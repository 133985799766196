export const HomeIco = ()=>{
    return<svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
    <path d="M10 15V12.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.39172 2.34955L2.61672 6.97455C1.96672 7.49122 1.55006 8.58288 1.69172 9.39955L2.80006 16.0329C3.00006 17.2162 4.13339 18.1745 5.33339 18.1745H14.6667C15.8584 18.1745 17.0001 17.2079 17.2001 16.0329L18.3084 9.39955C18.4417 8.58288 18.0251 7.49122 17.3834 6.97455L11.6084 2.35788C10.7167 1.64122 9.27506 1.64122 8.39172 2.34955Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}
;
export const CatalogIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M6.25 6.39143V5.5831C6.25 3.7081 7.75833 1.86643 9.63333 1.69143C11.8667 1.47476 13.75 3.2331 13.75 5.42476V6.57476" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.5001 18.3332H12.5001C15.8501 18.3332 16.4501 16.9915 16.6251 15.3582L17.2501 10.3582C17.4751 8.32484 16.8918 6.6665 13.3334 6.6665H6.66677C3.10843 6.6665 2.5251 8.32484 2.7501 10.3582L3.3751 15.3582C3.5501 16.9915 4.1501 18.3332 7.5001 18.3332Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.9128 10.0002H12.9203" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.07884 10.0002H7.08632" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;
export const CategoriesIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M17.4584 12.1248L12.1334 17.4498C10.9667 18.6165 9.05006 18.6165 7.87506 17.4498L2.55005 12.1248C1.38338 10.9582 1.38338 9.04152 2.55005 7.86652L7.87506 2.5415C9.04173 1.37484 10.9584 1.37484 12.1334 2.5415L17.4584 7.86652C18.6251 9.04152 18.6251 10.9582 17.4584 12.1248Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.20825 5.2085L14.7916 14.7918" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.7916 5.2085L5.20825 14.7918" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const TranslationsIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M18.3334 8.33317V12.4998C18.3334 16.6665 16.6667 18.3332 12.5001 18.3332H7.50008C3.33341 18.3332 1.66675 16.6665 1.66675 12.4998V7.49984C1.66675 3.33317 3.33341 1.6665 7.50008 1.6665H11.6667" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3334 8.33317H15.0001C12.5001 8.33317 11.6667 7.49984 11.6667 4.99984V1.6665L18.3334 8.33317Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.83325 10.8335H10.8333" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.83325 14.1665H9.16659" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const ObjectivesIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M16.0834 6.60023V10.8919C16.0834 13.4586 14.6167 14.5586 12.4167 14.5586H5.09171C4.71671 14.5586 4.35838 14.5253 4.02504 14.4503C3.81671 14.4169 3.61672 14.3586 3.43339 14.2919C2.18339 13.8253 1.42505 12.7419 1.42505 10.8919V6.60023C1.42505 4.03356 2.89171 2.93359 5.09171 2.93359H12.4167C14.2834 2.93359 15.625 3.72526 15.9834 5.53359C16.0417 5.86692 16.0834 6.20856 16.0834 6.60023Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.5842 9.10033V13.392C18.5842 15.9587 17.1176 17.0587 14.9176 17.0587H7.59254C6.97588 17.0587 6.41755 16.9754 5.93422 16.792C4.94255 16.4254 4.26755 15.667 4.02588 14.4504C4.35921 14.5254 4.71754 14.5587 5.09254 14.5587H12.4176C14.6176 14.5587 16.0842 13.4587 16.0842 10.892V6.60033C16.0842 6.20866 16.0509 5.85869 15.9842 5.53369C17.5676 5.86702 18.5842 6.98366 18.5842 9.10033Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.74884 10.9503C9.96387 10.9503 10.9489 9.96533 10.9489 8.7503C10.9489 7.53528 9.96387 6.55029 8.74884 6.55029C7.53381 6.55029 6.54883 7.53528 6.54883 8.7503C6.54883 9.96533 7.53381 10.9503 8.74884 10.9503Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.9834 6.91699V10.5837" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.5181 6.91699V10.5837" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const ConversationsIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M3.67063 12.9386L7.44563 16.7136C8.99563 18.2636 11.5123 18.2636 13.0706 16.7136L16.729 13.0553C18.279 11.5053 18.279 8.98864 16.729 7.43031L12.9456 3.66364C12.154 2.87197 11.0623 2.44697 9.94563 2.50531L5.77896 2.70531C4.1123 2.78031 2.7873 4.10531 2.70396 5.76364L2.50396 9.93031C2.45396 11.0553 2.87896 12.147 3.67063 12.9386Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.11239 10.1886C9.26298 10.1886 10.1957 9.2559 10.1957 8.10531C10.1957 6.95471 9.26298 6.02197 8.11239 6.02197C6.96179 6.02197 6.02905 6.95471 6.02905 8.10531C6.02905 9.2559 6.96179 10.1886 8.11239 10.1886Z" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M11.0291 14.3553L14.3624 11.022" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const UserExperiencesIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M18.3333 6.8915V3.52484C18.3333 2.19984 17.8 1.6665 16.475 1.6665H13.1083C11.7833 1.6665 11.25 2.19984 11.25 3.52484V6.8915C11.25 8.2165 11.7833 8.74984 13.1083 8.74984H16.475C17.8 8.74984 18.3333 8.2165 18.3333 6.8915Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.75008 7.09984V3.3165C8.75008 2.1415 8.21675 1.6665 6.89175 1.6665H3.52508C2.20008 1.6665 1.66675 2.1415 1.66675 3.3165V7.0915C1.66675 8.27484 2.20008 8.7415 3.52508 8.7415H6.89175C8.21675 8.74984 8.75008 8.27484 8.75008 7.09984Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.75008 16.475V13.1083C8.75008 11.7833 8.21675 11.25 6.89175 11.25H3.52508C2.20008 11.25 1.66675 11.7833 1.66675 13.1083V16.475C1.66675 17.8 2.20008 18.3333 3.52508 18.3333H6.89175C8.21675 18.3333 8.75008 17.8 8.75008 16.475Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5 12.9165H17.5" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M12.5 16.25H17.5" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
</svg>

export const StoresIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M2.5083 9.3501V13.0918C2.5083 16.8334 4.0083 18.3334 7.74997 18.3334H12.2416C15.9833 18.3334 17.4833 16.8334 17.4833 13.0918V9.3501" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99999 9.99984C11.525 9.99984 12.65 8.75817 12.5 7.23317L11.95 1.6665H8.05832L7.49999 7.23317C7.34999 8.75817 8.47499 9.99984 9.99999 9.99984Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.2584 9.99984C16.9417 9.99984 18.1751 8.63317 18.0084 6.95817L17.7751 4.6665C17.4751 2.49984 16.6417 1.6665 14.4584 1.6665H11.9167L12.5001 7.50817C12.6417 8.88317 13.8834 9.99984 15.2584 9.99984Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.6999 9.99984C6.0749 9.99984 7.31657 8.88317 7.4499 7.50817L7.63324 5.6665L8.03324 1.6665H5.49157C3.30824 1.6665 2.4749 2.49984 2.1749 4.6665L1.9499 6.95817C1.78324 8.63317 3.01657 9.99984 4.6999 9.99984Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.0001 14.1665C8.60841 14.1665 7.91675 14.8582 7.91675 16.2498V18.3332H12.0834V16.2498C12.0834 14.8582 11.3917 14.1665 10.0001 14.1665Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
export const AnalyticsIco =()=> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M1.66675 1.6665V15.8332C1.66675 17.2165 2.78341 18.3332 4.16675 18.3332H18.3334" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.16675 14.1668L7.99175 9.70017C8.62508 8.96684 9.75008 8.91683 10.4334 9.60849L11.2251 10.4002C11.9084 11.0835 13.0334 11.0418 13.6667 10.3085L17.5001 5.8335" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
export const SettingsIco = () =><svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<path d="M18.3333 6.89297V3.5263C18.3333 2.2013 17.8 1.66797 16.475 1.66797H13.1083C11.7833 1.66797 11.25 2.2013 11.25 3.5263V6.89297C11.25 8.21797 11.7833 8.7513 13.1083 8.7513H16.475C17.8 8.7513 18.3333 8.21797 18.3333 6.89297Z" stroke="#0D1F00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.75033 7.1013V3.31797C8.75033 2.14297 8.21699 1.66797 6.89199 1.66797H3.52533C2.20033 1.66797 1.66699 2.14297 1.66699 3.31797V7.09297C1.66699 8.2763 2.20033 8.74297 3.52533 8.74297H6.89199C8.21699 8.7513 8.75033 8.2763 8.75033 7.1013Z" stroke="#0D1F00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.75033 16.475V13.1083C8.75033 11.7833 8.21699 11.25 6.89199 11.25H3.52533C2.20033 11.25 1.66699 11.7833 1.66699 13.1083V16.475C1.66699 17.8 2.20033 18.3333 3.52533 18.3333H6.89199C8.21699 18.3333 8.75033 17.8 8.75033 16.475Z" stroke="#0D1F00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 12.918H17.5" stroke="#0D1F00" stroke-width="1.5" stroke-linecap="round"/>
<path d="M12.5 16.25H17.5" stroke="#0D1F00" stroke-width="1.5" stroke-linecap="round"/>
</svg>

export const PlusIco = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    
  >
    <path
      d="M5.25 5.25V0.75H6.75V5.25H11.25V6.75H6.75V11.25H5.25V6.75H0.75V5.25H5.25Z"
      fill="#0584FE"
    />
  </svg>
);

export const ActionsIco = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    
  >
    <path
      d="M9 10.0938C9.60406 10.0938 10.0938 9.60406 10.0938 9C10.0938 8.39594 9.60406 7.90625 9 7.90625C8.39594 7.90625 7.90625 8.39594 7.90625 9C7.90625 9.60406 8.39594 10.0938 9 10.0938Z"
      fill="#0584FE"
      stroke="#0584FE"
      strokeWidth="0.5"
    />
    <path
      d="M13.5 10.0938C14.1041 10.0938 14.5938 9.60406 14.5938 9C14.5938 8.39594 14.1041 7.90625 13.5 7.90625C12.8959 7.90625 12.4062 8.39594 12.4062 9C12.4062 9.60406 12.8959 10.0938 13.5 10.0938Z"
      fill="#0584FE"
      stroke="#0584FE"
      strokeWidth="0.5"
    />
    <path
      d="M4.5 10.0938C5.10406 10.0938 5.59375 9.60406 5.59375 9C5.59375 8.39594 5.10406 7.90625 4.5 7.90625C3.89594 7.90625 3.40625 8.39594 3.40625 9C3.40625 9.60406 3.89594 10.0938 4.5 10.0938Z"
      fill="#0584FE"
      stroke="#0584FE"
      strokeWidth="0.5"
    />
  </svg>
);

export const FourDotsIco = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    
  >
    <path
      d="M7.56006 11.5C6.96911 11.5 6.38395 11.3836 5.83798 11.1575C5.29202 10.9313 4.79594 10.5998 4.37808 10.182C3.96021 9.76412 3.62875 9.26804 3.4026 8.72208C3.17645 8.17611 3.06006 7.59095 3.06006 7C3.06006 6.40905 3.17645 5.82389 3.4026 5.27792C3.62875 4.73196 3.96021 4.23588 4.37808 3.81802C4.79594 3.40016 5.29202 3.06869 5.83798 2.84254C6.38395 2.6164 6.96911 2.5 7.56006 2.5C8.75353 2.5 9.89813 2.97411 10.742 3.81802C11.586 4.66193 12.0601 5.80653 12.0601 7C12.0601 8.19347 11.586 9.33807 10.742 10.182C9.89813 11.0259 8.75353 11.5 7.56006 11.5ZM7.56006 21.5C6.36658 21.5 5.22199 21.0259 4.37808 20.182C3.53416 19.3381 3.06006 18.1935 3.06006 17C3.06006 15.8065 3.53416 14.6619 4.37808 13.818C5.22199 12.9741 6.36658 12.5 7.56006 12.5C8.75353 12.5 9.89813 12.9741 10.742 13.818C11.586 14.6619 12.0601 15.8065 12.0601 17C12.0601 18.1935 11.586 19.3381 10.742 20.182C9.89813 21.0259 8.75353 21.5 7.56006 21.5ZM17.5601 11.5C16.9691 11.5 16.3839 11.3836 15.838 11.1575C15.292 10.9313 14.7959 10.5998 14.3781 10.182C13.9602 9.76412 13.6287 9.26804 13.4026 8.72208C13.1765 8.17611 13.0601 7.59095 13.0601 7C13.0601 6.40905 13.1765 5.82389 13.4026 5.27792C13.6287 4.73196 13.9602 4.23588 14.3781 3.81802C14.7959 3.40016 15.292 3.06869 15.838 2.84254C16.3839 2.6164 16.9691 2.5 17.5601 2.5C18.7535 2.5 19.8981 2.97411 20.742 3.81802C21.586 4.66193 22.0601 5.80653 22.0601 7C22.0601 8.19347 21.586 9.33807 20.742 10.182C19.8981 11.0259 18.7535 11.5 17.5601 11.5ZM17.5601 21.5C16.3666 21.5 15.222 21.0259 14.3781 20.182C13.5342 19.3381 13.0601 18.1935 13.0601 17C13.0601 15.8065 13.5342 14.6619 14.3781 13.818C15.222 12.9741 16.3666 12.5 17.5601 12.5C18.7535 12.5 19.8981 12.9741 20.742 13.818C21.586 14.6619 22.0601 15.8065 22.0601 17C22.0601 18.1935 21.586 19.3381 20.742 20.182C19.8981 21.0259 18.7535 21.5 17.5601 21.5Z"
      fill="#B1B1B1"
    />
  </svg>
);

export const FiltersIco = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    
  >
    <g clip-path="url(#clip0_733_652)">
      <path
        d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"
        fill="#898989"
      />
    </g>
    <defs>
      <clipPath id="clip0_733_652">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const KiosksIcon = () => (

<svg viewBox="0 0 24 24" fill="none" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 18H12.01M8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.07989 21 8.2 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>);

export const SessionIcon = () => (
  <svg viewBox="0 0 48 48" fill="none" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fill-opacity="0.01"></rect> <path d="M24 43.9998H8V3.99977H40V23.9998" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M35.5 43.9998V30.9998" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M31 34.4998L32.5 32.9998L35.5 29.9998L38.5 32.9998L40 34.4998" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16 15.9998H32" stroke="#000000" stroke-width="4" stroke-linecap="round"></path> <path d="M16 23.9998H24" stroke="#000000" stroke-width="4" stroke-linecap="round"></path> </g></svg>
)  
export const ExperienceIcon = () => (
<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"  width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z" clip-rule="evenodd"/>
</svg>
)

export const ExpandIcon = () => (
  <svg 
    height="20px" 
    width="20px" 
    version="1.1" 
    id="Layer_1" 
     
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 512.001 512.001" 
    xmlSpace="preserve" 
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier"> 
      <path style={{fill: "#2D527C"}} d="M340.41,225.225c-3.649,0-7.298-1.393-10.082-4.176l-40.65-40.65 c-2.673-2.675-4.176-6.301-4.176-10.082c0-3.781,1.503-7.407,4.176-10.082L417.439,32.472c5.568-5.567,14.597-5.567,20.166,0 c5.568,5.568,5.568,14.597,0,20.166l-117.68,117.678l20.485,20.485l34.001-34.001c5.568-5.567,14.595-5.565,20.165,0 c5.568,5.568,5.568,14.597,0,20.166l-44.083,44.082C347.709,223.831,344.06,225.225,340.41,225.225z"></path> 
      <polygon style={{fill: "#CEE8FA"}} points="349.852,48.611 497.713,14.275 463.376,162.136"></polygon> 
      <g> 
        <path style={{fill: "#2D527C"}} d="M463.378,176.394c-3.73,0-7.372-1.464-10.084-4.176L339.769,58.693 c-3.662-3.663-5.05-9.024-3.626-14.003c1.424-4.981,5.437-8.796,10.483-9.968L494.487,0.384c4.795-1.115,9.826,0.325,13.308,3.807 c3.481,3.481,4.921,8.512,3.806,13.308L477.264,165.36c-1.172,5.046-4.988,9.06-9.968,10.484 C466.004,176.215,464.685,176.394,463.378,176.394z M378.095,56.69l77.202,77.202l23.35-100.552L378.095,56.69z"></path> 
        <path style={{fill: "#2D527C"}} d="M84.45,483.706c-3.649,0-7.299-1.393-10.082-4.176c-5.568-5.568-5.568-14.597,0-20.166 l117.68-117.678L171.561,321.2l-34.001,34.001c-5.567,5.569-14.595,5.567-20.165,0c-5.568-5.568-5.568-14.597,0-20.166 l44.084-44.082c5.567-5.567,14.595-5.567,20.165,0l40.65,40.65c2.673,2.675,4.176,6.301,4.176,10.082 c0,3.781-1.503,7.407-4.176,10.082L94.532,479.529C91.749,482.314,88.098,483.706,84.45,483.706z"></path> 
      </g> 
      <polygon style={{fill: "#CEE8FA"}} points="162.119,463.391 14.258,497.728 48.595,349.867"></polygon> 
      <g> 
        <path style={{fill: "#2D527C"}} d="M14.258,511.986c-3.739,0-7.377-1.472-10.082-4.176c-3.481-3.481-4.921-8.512-3.806-13.308 l34.336-147.861c1.172-5.046,4.988-9.06,9.968-10.484c4.982-1.423,10.342-0.034,14.003,3.626l113.525,113.525 c3.662,3.663,5.05,9.024,3.626,14.003c-1.424,4.981-5.437,8.796-10.483,9.968L17.484,511.617 C16.414,511.865,15.332,511.986,14.258,511.986z M56.674,378.112l-23.35,100.552l100.552-23.35L56.674,378.112z"></path> 
        <path style={{fill: "#2D527C"}} d="M469.432,441.796c-3.65,0-7.298-1.393-10.082-4.176L341.671,319.942l-20.485,20.484l34,34 c5.568,5.568,5.568,14.597,0,20.166c-5.568,5.567-14.597,5.567-20.166,0l-44.082-44.082c-2.673-2.675-4.176-6.301-4.176-10.082 c0-3.781,1.503-7.407,4.176-10.082l40.65-40.65c5.568-5.567,14.597-5.565,20.165,0l127.76,127.762 c5.568,5.568,5.568,14.597,0,20.166C476.73,440.403,473.081,441.796,469.432,441.796z"></path> 
      </g> 
      <polygon style={{fill: "#CEE8FA"}} points="463.376,349.867 497.713,497.728 349.852,463.391"></polygon> 
      <g> 
        <path style={{fill: "#2D527C"}} d="M497.713,511.986c-1.074,0-2.156-0.121-3.225-0.369l-147.861-34.336 c-5.046-1.172-9.059-4.988-10.483-9.968c-1.424-4.979-0.036-10.34,3.626-14.003l113.525-113.525 c3.663-3.66,9.026-5.048,14.003-3.626c4.981,1.424,8.796,5.437,9.968,10.484l34.124,146.944c0.398,1.312,0.612,2.702,0.612,4.142 c0,7.832-6.317,14.19-14.135,14.259C497.815,511.986,497.762,511.986,497.713,511.986z M378.095,455.312l100.552,23.35 l-23.35-100.552L378.095,455.312z"></path> 
        <path style={{fill: "#2D527C"}} d="M170.299,226.485c-3.65,0-7.298-1.392-10.082-4.176L32.457,94.55 c-5.568-5.568-5.568-14.597,0-20.166c5.568-5.567,14.597-5.567,20.166,0l117.678,117.678l20.485-20.485l-34-34 c-5.568-5.568-5.568-14.597,0-20.166c5.568-5.567,14.597-5.567,20.166,0l44.082,44.082c2.673,2.675,4.176,6.301,4.176,10.082 s-1.503,7.407-4.176,10.082l-40.65,40.65C177.598,225.092,173.948,226.485,170.299,226.485z"></path> 
      </g> 
      <polygon style={{fill: "#CEE8FA"}} points="48.595,162.136 14.258,14.275 162.119,48.611"></polygon> 
      <path style={{fill: "#2D527C"}} d="M48.593,176.394c-1.308,0-2.626-0.18-3.92-0.55c-4.981-1.424-8.796-5.437-9.968-10.484L0.37,17.499 c-1.114-4.795,0.325-9.827,3.806-13.308c3.482-3.481,8.514-4.918,13.308-3.807L165.345,34.72c5.046,1.172,9.059,4.988,10.483,9.968 c1.424,4.979,0.036,10.34-3.626,14.003L58.677,172.217C55.966,174.93,52.323,176.394,48.593,176.394z M33.324,33.34l23.35,100.552 l77.202-77.202L33.324,33.34z"></path> 
    </g>
  </svg>
);