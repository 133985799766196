import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

const DeleteModal = ({ name, title, handleDelete }) => {
  const color = useColorModeValue("red.500", "red.500");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const cancelButtonRef = React.useRef(null);

  // Handle modal open/close with tooltip control
  const handleModalOpen = () => {
    setShowTooltip(false);
    onOpen();
  };

  const handleModalClose = () => {
    setShowTooltip(false);
    onClose();
  };

  // Handle delete operation
  const handleDeleteClick = () => {
    handleModalClose();
    handleDelete();
  };

  return (
    <>
      <Tooltip 
        label="Delete" 
        hasArrow 
        isOpen={showTooltip}
        onClose={() => setShowTooltip(false)}
      >
        <Button
          variant="outline"
          color={color}
          colorScheme="red"
          onClick={handleModalOpen}
          marginRight="4"
          onMouseEnter={() => !isOpen && setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <DeleteIcon />
        </Button>
      </Tooltip>

      <Modal
        isCentered
        onClose={handleModalClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        initialFocusRef={cancelButtonRef} // Set focus on Cancel button
        returnFocusOnClose={false} // Prevent focus returning to delete button
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg">{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div tabIndex={-1} /> {/* Hidden element for maintaining design */}
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleModalClose}
              ref={cancelButtonRef} // Set ref here
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;
