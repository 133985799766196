import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
  useToast,
  FormControl,
    List,
  ListItem
} from "@chakra-ui/react";

import Fetch from "../../common/fetch";
import { FormC } from "../../common/validate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import InputField from "components/input";
import { useRef } from "react";
const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Brazil", code: "BR" },
  { name: "Brunei", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo (Congo-Brazzaville)", code: "CG" },
  { name: "Congo (Congo-Kinshasa)", code: "CD" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Greece", code: "GR" },
  { name: "Grenada", code: "GD" },
  { name: "Guatemala", code: "GT" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Honduras", code: "HN" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Mexico", code: "MX" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nigeria", code: "NG" },
  { name: "Norway", code: "NO" },
  { name: "Pakistan", code: "PK" },
  { name: "Panama", code: "PA" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Qatar", code: "QA" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "South Africa", code: "ZA" },
  { name: "Spain", code: "ES" },
  { name: "United States", code: "US" },
  { name: "United Kingdom", code: "UK" },
];

function NewCatalog(props) {
  const history = useHistory();
  const catalogueId = props.match.params?.id;
  const addForm = catalogueId === "add";
  const [isLoading, setisLoading] = useState(false);
  const [DatabaseNames, setDatabaseNames] = useState([]);
  const [state, setState] = useState({
    name: "",
    prefix: "",
    country: "",
    database: ""
    });

  const toast = useToast();
  useEffect(() => {
    if (!addForm) {
      Fetch(`client/${catalogueId}/`).then((res) => {
        if (res.status) {
          const { data } = res;
          setState({
            ...state,
            name: data.name,
            prefix: data.prefix || "",
            country: data.country || "",
            database: data.database_name || ""
                    });
        }
      });
    }
    FetchDatabaseNames()
  }, []);



  const onSubmit = () => {
    setisLoading(true);

    const requestBody = {
      name: state.name,
      prefix: state.prefix,
      country: state.country,
      database_name: state.database.trim()
        };

    Fetch(`client/${addForm ? "" : catalogueId + "/"}`, requestBody, {
      method: addForm ? "post" : "put",
    }).then((res) => {
      if (res.status) {
        setisLoading(false);
        toast({
          title: `Client  ${addForm ? "Created" : "updated"}.`,
          description: addForm
            ? "The client has been successfully added."
            : "The client details have been successfully updated.",
          status: "success",
          duration: 500,
          isClosable: false,
        });
        setState({
          name: "",
          prefix: "",
          country: "",
          database: ""
                });
        setTimeout(() => {
          history.push({
            pathname: "/admin/clients",
          });
        }, 500);
      } else {
        handleNewError(res.data);
        setisLoading(false);
      }
    });
  };

  const SearchableSelect = ({ 
    options, 
    onChange, 
    value, 
    placeholder, 
    error, 
    inputName,
    label 
  }) => {
    const [searchValue, setSearchValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const selectRef = useRef(null);

    // Initialize selected option based on value
    useEffect(() => {
      if (value && options.length > 0) {
        if (inputName === "country") {
          // For country, find by code
          const option = options.find(opt => opt.code === value );
          if (option) setSelectedOption(option);
        } else if (inputName === "database") {
          // For database, we might need to find differently based on database structure
          const option = options.find(opt => opt.database_name === value);
          if (option) setSelectedOption(option);
        }
      }
    }, [value, options, inputName]);

    // Filter options based on search value
    const filteredOptions = options.filter(option => {
      if (inputName === "country") {
        const query = searchValue.toLowerCase();

        if (query.length === 2) {
          // Agar sirf 2 letters hain, toh pehle code match karega
          return option.code.toLowerCase().includes(query);
        }
      
        // Agar query 2 se zyada letters ki hai, toh country name se filter karega
        return (
          option.name.toLowerCase().includes(query) ||
          option.code.toLowerCase().includes(query) // Code ko bhi consider karega
        );
      } else if (inputName === "database") {
        return option.database_name && option.database_name.toLowerCase().includes(searchValue.toLowerCase());
      }
      return false;
    });

    // Handle click outside to close dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    // Display value for the input
    const getDisplayValue = () => {
      if (selectedOption) {
        if (inputName === "country") {
          return selectedOption.name;
        } else if (inputName === "database") {
          return selectedOption.display_name;
        }
      }
      return searchValue;
    };

    // Handle option selection
    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      setSearchValue("");
      setIsOpen(false);
      
      if (inputName === "country") {
        onChange(option.code);
      } else if (inputName === "database") {
        onChange(option.database_name);
      }
    };

    return (
      <Box position="relative" ref={selectRef}>
        <InputField
          value={getDisplayValue()}
          errorText={error}
          onChange={(e) => {
            setSearchValue(e.target.value);
            if (!isOpen) setIsOpen(true);
            if (selectedOption) {
              setSelectedOption(null);
              onChange("");
            }
          }}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder || `Select ${inputName === "country" ? "a country" : "a database"}`}
          bg="white"
          isInvalid={!!error}
          label ={label || (inputName === "country" ? "Country" : "Database Name")}
        />

        {isOpen && (
          <List
            position="absolute"
            width="100%"
            bg="white"
            boxShadow="md"
            mt="2px"
            zIndex={10}
            maxH="200px"
            overflowY="auto"
            borderRadius="md"
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <ListItem
                  key={inputName === "country" ? option.code : option.id}
                  px={3}
                  py={2}
                  _hover={{ bg: "gray.100" }}
                  cursor="pointer"
                  onClick={() => handleOptionSelect(option)}
                >
                  {inputName === "country" 
                    ? `${option.name} - ${option.code}`
                    : option.display_name}
                </ListItem>
              ))
            ) : (
              <ListItem px={3} py={2} color="gray.500">
                No options found
              </ListItem>
            )}
          </List>
        )}
      </Box>
    );
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };


  const FetchDatabaseNames = () =>{
    Fetch(`client/databases/`).then((res) => {
      if (res.status) {
        const { data } = res;
        setDatabaseNames(data);
      }
    });
  }
  const { handleSubmit, errors, handleNewError } = FormC({
    values: { ...state },
    onSubmit,
  });

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px", lg: "80px" }}
      bgColor={"white"}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <Stack direction="row" alignItems={"center"} width={"fit-content"}>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              {!addForm ? "Update Client" : "Add New Client"}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <NavLink to="/admin/clients">
              <Button
                variant="outline"
                textColor={"#0584FE"}
                borderColor={"#0584FE"}
              >
                Cancel
              </Button>
            </NavLink>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              {!addForm ? "Update" : "Save"} & Close
            </Button>
          </Stack>
        </div>
        <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"15px"}
          borderRadius={"8px"}
        >
          <Grid templateColumns="repeat(1, 1fr)" gap={3}>
            <GridItem w="100%">
      
              <FormControl isInvalid={errors?.name}>
                <InputField
                  label="Client Name"
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                  placeholder="Enter Name"
                  name="name"
                  value={state.name}
                  onChange={onChange}
                  errorText={errors?.name}
                />
              </FormControl>
              <FormControl isInvalid={errors?.prefix}>
                <InputField
                  label="2-Letter Client Name Abbreviation"
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                  placeholder="2-Letter Client Name Abbreviation"
                  name="prefix"
                  value={state.prefix}
                  onChange={onChange}
                  errorText={errors.prefix}
                />
              </FormControl>
              <GridItem w="100%">
                <FormControl isInvalid={errors?.country}>
                  <SearchableSelect
                  label="Country"
                    options={countries}
                    value={state.country}
                    onChange={(value) => setState({ ...state, country: value })}
                    placeholder="Select Country"
                    error={errors?.country}
                    inputName="country"

                  />
                  
                </FormControl>
              </GridItem>
              <FormControl isInvalid={errors?.database} mt={4}>
                {/* <InputField
                  label="Database Name"
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                  placeholder="Enter database name"
                  name="database"
                  value={state.database}
                  onChange={onChange}

                  onKeyPress={(e) => onKeyPressAlpha(e, true)}
                  onPaste={(e) => onPasteAlpha(e, true)}
                  errorText={errors?.database || errors?.database_name}
                /> */}


                  <SearchableSelect
                    options={DatabaseNames}
                    label="Client Database"
                    value={state.database}
                    onChange={(value) => setState({ ...state, database: value })}
                    placeholder="Select Client Database"
                    inputName="database"
                    error={errors?.database ||  errors?.database_name}

                  />
              </FormControl>
            </GridItem>
          </Grid>
        </Box>
      </form>
    </Flex>
  );
}

export default NewCatalog;
