import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import {
  Input,
  Table,
  Tbody as TableBody,
  Tr as TableRow,
  Select,
  Thead,
  Flex,
  useColorModeValue,
  Text,
  Td,
  Center,
} from "@chakra-ui/react";
// "" React components
import SoftPagination from "./SoftPagination";

// "" React example components
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
// import SoftButton from "components/SoftButton";
// import { useNavigate } from "react-router-dom";
function formatLastModified(last_modified) {
    const date = new Date(last_modified);
    
    // Convert to the desired format: YYYY-MM-DD HH:MM:SS UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
    // Construct the final string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
  }
function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  path = null,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries
    : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;
  // const navigate = useNavigate();

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }
  return (
    <>
      {/* {entriesPerPage || canSearch ? (
                <Flex display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {entriesPerPage && (
                        <Flex display="flex" alignItems="center">
                            <Select
                                value={defaultValue}
                                onChange={setEntriesPerPage}
                                size="md"
                                borderRadius={"8px"}
                                fontSize="sm"
                                placeholder="Select parent item"
                            >
                                {
                                    entries?.map((items, key) => {
                                        return <option key={key} value={items}>{items}</option>
                                    })
                                }
                            </Select>
                            <Text fontSize='12px' color={textColor}>
                                &nbsp;&nbsp;entries per page
                            </Text>
                        </Flex>
                    )}
                </Flex>
            ) : null} */}

      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, key) => (
            <TableRow
              my=".8rem"
              pl="0px"
              color="gray.400"
              key={key}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, key) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                  fontWeight="bold"
                  color="black"
                >
                  <Text fontWeight="bold" color="black">
                    {column.render("Header")}
                  </Text>
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </Thead>
        <TableBody {...getTableBodyProps()}>
          {page?.length ? (
            page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow
                  backgroundColor={
                    key % 2 === 0 ? "" : "rgba(248, 248, 248, 1)"
                  }
                  key={key}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, key) => (
                    <DataTableBodyCell
                      key={key}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.renderCell
                        ? cell.renderCell()
                        : cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <Td colSpan={headerGroups[0]?.headers.length}>
                <Center height="100px">
                  <Text fontSize="md" color="gray.500">
                    No Data Found
                  </Text>
                </Center>
              </Td>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/* <div
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <div mb={{ xs: 3, sm: 0 }}>
                        <p variant="button" color="secondary" fontWeight="regular">
                            Showing {entriesStart} to {entriesEnd} of {rows.length} entries
                        </p>
                    </div>
                )}
                {pageOptions.length > 1 && (
                    <SoftPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <SoftPagination item onClick={() => previousPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </SoftPagination>
                        )}
                        {renderPagination.length > 6 ? (
                            <div width="5rem" mx={1}>
                                <Input
                                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                                    value={customizedPageOptions[pageIndex]}
                                    onChange={(handleInputPagination, handleInputPaginationValue)}
                                />
                            </div>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <SoftPagination item onClick={() => nextPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </SoftPagination>
                        )}
                    </SoftPagination>
                )}
            </div> */}
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  path: PropTypes.string,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  path: PropTypes.string || null,
};

export default DataTable;
