import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
  useToast,
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  List,
  ListItem
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import Fetch from "../../common/fetch";
import { FormC } from "../../common/validate";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import InputField from "components/input";
import { onKeyPress } from "common/array";
import { preventChanges } from "common/array";

const SearchableSelect = ({ options, onChange, value, name, placeholder, error, disabled = false }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find(opt => opt.value === value) || null
  );
  const selectRef = useRef(null);
  
  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const matchingOption = options.find(opt => opt.value === value);
    setSelectedOption(matchingOption || null);
  }, [value, options]);
  
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setSearchValue("");
    setIsOpen(false);
    if (!disabled) {
      onChange({
        target: {
          name: name,
          value: option.value
        }
      });
    }
  };
  
  return (
    <Box position="relative" ref={selectRef}>
      <InputField
        value={selectedOption ? selectedOption.label : searchValue}
        onChange={(e) => {
          if (!disabled) {
            setSearchValue(e.target.value);
            if (!isOpen) setIsOpen(true);
            if (selectedOption && e.target.value !== selectedOption.label) {
              setSelectedOption(null);
              onChange({
                target: {
                  name: name,
                  value: ""
                }
              });
            }
          }
        }}
        onFocus={() => !disabled && setIsOpen(true)}
        placeholder={placeholder || "Select an option"}
        borderRadius={"8px"}
        isInvalid={!!error}
        isDisabled={disabled}
        cursor={disabled ? "not-allowed" : "pointer"}
      />
      
      {isOpen && !disabled && (
        <List
          position="absolute"
          width="100%"
          bg="white"
          boxShadow="md"
          mt="2px"
          zIndex={10}
          maxH="200px"
          overflowY="auto"
          borderRadius="md"
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map(option => (
              <ListItem
                key={option.value}
                px={3}
                py={2}
                _hover={{ bg: "gray.100" }}
                cursor="pointer"
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </ListItem>
            ))
          ) : (
            <ListItem px={3} py={2} color="gray.500">
              No options found
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

function NewCatalog(props) {
  const history = useHistory();
  const catalogueId = props.match.params?.id;
  const addForm = catalogueId === "add";
  const [isLoading, setisLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [experience, setExperience] = useState([]);
  const [provision, setProvision] = useState(false);
  const [state, setState] = useState({
    serial_number: "",
  });

  const toast = useToast();
  const onSubmit = () => {
    setisLoading(true);
    const requestBody = {
      serial_number: state.serial_number,
      ...state,
    };

    Fetch(`kiosk/${addForm ? "" : catalogueId + "/"}`, requestBody, {
      method: addForm ? "post" : "put",
    }).then((res) => {
      if (res.status) {
        setisLoading(false);
        toast({
          title: addForm ? "Kiosk Created" : "Kiosk Updated",
          description: addForm
            ? "The kiosk has been successfully created."
            : "The kiosk has been successfully updated.",
          status: "success",
          duration: 1000,
          isClosable: false,
        });
        
        setState({});

        setTimeout(() => {
          history.push({
            pathname: "/admin/kiosks",
          });
        }, 500);
      } else {
        if (res?.data?.serial_number) {
          handleNewError({ serial_number: res?.data?.serial_number });
        }
        if (res?.data?.client_id) {
          handleNewError({ client_id: res?.data?.client_id });
        }
        if (res?.data?.experience_id) {
          handleNewError({ experience_id: res?.data?.experience_id });
        }

        setisLoading(false);
      }
    });
  };
  
  const onChange = (e) => {
    const { name, value } = e.target;

    if (value.length >= 50) {
      handleNewError({ [name]: "value must be less than 50 characters" });
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!addForm) {
      Fetch(`kiosk/${catalogueId}/`).then((res) => {
        if (res.status) {
          const { data } = res;
          setState({
            name: data.name,
            serial_number: data?.serial_number,
          });
          const newState = {
            name: data.name,
            serial_number: data?.serial_number,
          };
          console.log(data.status, "status==================")
          if (data.status == "provisioned") {
            newState.client_id = data?.client_id?.id;
            newState.experience_id = data?.experience_id?.id;
          }

          setState(newState);
          setProvision(data.status);
        }
      });
    }
    fetchExperiences();
    fetchClients();
  }, [addForm]);
console.log(state, "state================")
  const fetchClients = () => {
    Fetch(`client/?page=0`).then((res) => {
      if (res.status) {
        const { data } = res;
        setClients(data);
      }
    });
  };
  
  const fetchExperiences = () => {
    Fetch(`kiosk/experiences/?page=0`).then((res) => {
      if (res.status) {
        const { data } = res;
        setExperience(data);
      }
    });
  };

  // Convert data to options format for searchable select
  const clientOptions = clients.map((client) => ({
    value: client.id,
    label: client.name
  }));
  
  const experienceOptions = experience.map((exp) => ({
    value: exp.id,
    label: exp.version
  }));

  const { handleSubmit, errors, handleNewError } = FormC({
    values: { ...state },
    onSubmit,
  });

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px", lg: "80px" }}
      bgColor={"white"}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <Stack direction="row" alignItems={"center"} width={"fit-content"}>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              {!addForm ? "Update Kiosk" : "Add New Kiosk"}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <NavLink to="/admin/kiosks">
              <Button
                variant="outline"
                textColor={"#0584FE"}
                borderColor={"#0584FE"}
              >
                Cancel
              </Button>
            </NavLink>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              {!addForm ? "Update" : "Save"} & Close
            </Button>
          </Stack>
        </div>
        <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"15px"}
          borderRadius={"8px"}
        >
          {addForm && (
            <Grid templateColumns="repeat(1, 1fr)" gap={3}>
              <GridItem w="100%">
                <InputField
                  label="Serial Number"
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                  placeholder="Enter serial number"
                  name="serial_number"
                  value={state.serial_number}
                  onChange={onChange}
                  errorText={errors?.serial_number}
                />
              </GridItem>
            </Grid>
          )}
          {!addForm && (
            <>
              <Grid templateColumns="repeat(1, 1fr)" gap={3}>
                <GridItem w="100%">
                  <InputField
                    label="Serial Number"
                    size="md"
                    borderRadius={"8px"}
                    fontSize="sm"
                    placeholder="Enter serial number"
                    name="serial_number"
                    disabled={true}
                    readOnly
                    value={state.serial_number}
                    onKeyPress={preventChanges}
                    onPaste={preventChanges}
                    errorText={errors?.serial_number}
                  />
                </GridItem>

                <GridItem w="100%">
                  <InputField
                    label="Name"
                    size="md"
                    borderRadius={"8px"}
                    fontSize="sm"
                    placeholder="Enter Name"
                    name="name"
                    value={state?.name}
                    type="text"
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    onPaste={onKeyPress}
                    errorText={errors?.name}
                  />
                </GridItem>
              </Grid>

              <GridItem w="100%" mt={4}>
                <FormControl isInvalid={errors?.experience_id}>
                  <FormLabel fontWeight="bold">Experience</FormLabel>
                  <SearchableSelect
                    options={experienceOptions}
                    value={state.experience_id}
                    onChange={provision === "unprovisioned" ? preventChanges : onChange}
                    name="experience_id"
                    placeholder="Select an experience"
                    error={errors?.experience_id}
                    disabled={provision === "unprovisioned"}
                  />
                  <FormErrorMessage>{errors?.experience_id}</FormErrorMessage>
                </FormControl>
              </GridItem>
              
              <GridItem w="100%" mt={4}>
                <FormControl isInvalid={errors?.client_id}>
                  <FormLabel fontWeight="bold">Client</FormLabel>
                  <SearchableSelect
                    options={clientOptions}
                    value={state.client_id}
                    onChange={provision === "unprovisioned" ? preventChanges : onChange}
                    name="client_id"
                    placeholder="Select a client"
                    error={errors?.client_id}
                    disabled={provision === "unprovisioned"}
                  />
                  <FormErrorMessage>{errors?.client_id}</FormErrorMessage>
                </FormControl>
              </GridItem>
              
              {provision === "unprovisioned" && (
                <Text fontSize="sm" color="red.500" mt={4}>
                  * Experience and Client inputs will be disabled until the kiosk is assigned to the experience and client.
                </Text>
              )}
            </>
          )}
        </Box>
      </form>
    </Flex>
  );
}

export default NewCatalog;