import { useState, useRef, useEffect } from "react";
import {
    Box,
    Input,
    List,
    ListItem
  } from "@chakra-ui/react";
const SearchableSelect = ({ options, onChange, value, placeholder }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find(opt => opt.value === value) || null
  );
  const selectRef = useRef(null);
  
  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <Box position="relative" ref={selectRef}>
      <Input
        value={selectedOption ? selectedOption.label : searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          if (!isOpen) setIsOpen(true);
          if (selectedOption && e.target.value !== selectedOption.label) {
            setSelectedOption(null);
            onChange("");
          }
        }}
        onFocus={() => setIsOpen(true)}
        placeholder={placeholder || "Select an option"}
        bg="white"
      />
      
      {isOpen && (
        <List
          position="absolute"
          width="100%"
          bg="white"
          boxShadow="md"
          mt="2px"
          zIndex={10}
          maxH="200px"
          overflowY="auto"
          borderRadius="md"
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map(option => (
              <ListItem
                key={option.value}
                px={3}
                py={2}
                _hover={{ bg: "gray.100" }}
                cursor="pointer"
                onClick={() => {
                  setSelectedOption(option);
                  setSearchValue("");
                  setIsOpen(false);
                  onChange(option.value);
                }}
              >
                {option.label}
              </ListItem>
            ))
          ) : (
            <ListItem px={3} py={2} color="gray.500">
              No options found
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

export default SearchableSelect
