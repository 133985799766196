import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Image,
  Stack,
  Switch,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import Editor from "@monaco-editor/react";
import YAML from "yaml";
import { FormC } from "common/validate";
import { ExpandIcon } from "components/Icons/menu";
import Fetch from "common/fetch";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import InputField from "components/input";

const TabbedExperienceForm = (props) => {
  const MAX_IMAGES = 20;
  const MAX_FILE_SIZE = 3 * 1024 * 1024;

  const history = useHistory();
  const color = useColorModeValue("blue.500", "white");
  const catalogueId = props.match.params?.id;
  const addForm = catalogueId === "add";
  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState({
    version: "",
    description: "",
    workflow_instructions: "",
    system_instructions: "",
    images: [],
    active_time: [],
    order: [],
    is_published: false,
  });
  const [isLoading, setisLoading] = useState(false);
  const [deleteAds, setdeleteAds] = useState([]);
  const [listIds, setListIds] = useState([]);
  const [imageObjects, setImageObjects] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [yamlErrors, setYamlErrors] = useState({})
  const [isEditorFullScreen, setIsEditorFullScreen] = useState({
    system: false,
    stage: false,
    workflow: false,
  });
  const [formComplete, setFormComplete] = useState({
    overview: false,
    instructions: false,
    media: false
  });

  const toggleFullScreen = (editor) => {
    setIsEditorFullScreen((prev) => ({ ...prev, [editor]: !prev[editor] }));
  };
  const toast = useToast();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateYaml = (code, instruct) => {
    try {
      YAML.parse(code);
      if(yamlErrors[instruct]){
        setYamlErrors(prevErr => {
          const newErrors = {...prevErr};
          delete newErrors[instruct];
          return newErrors;
        });
      }
      return true;
    } catch (e) {
      setYamlErrors((prevErr)=>({
        ...prevErr,
        [instruct]: "Incorrect yaml code" 
      }));
      return false;
    }
  };

  const handleYamlChange = (value, name) => {
    if (errors[name]) {
      delete errors[name];
    }

    setFormData({ ...formData, [name]: value });
    validateYaml(value, name);
  };

  const validateFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    return allowedTypes.includes(file.type);
  };

  const validateFileSize = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };
  
  useEffect(() => {
    if (!addForm) {
      Fetch(`kiosk/experiences/${catalogueId}/`).then((res) => {
        if (res.status) {
          const { data } = res;
          setFormData({
            ...formData,
            version: data.version || "",
            description: data.description || "",
            workflow_instructions: data.workflow_instructions || "",
            system_instructions: data.system_instructions || "",
            is_published: data.is_published || false,
            images: data.images,
            order: data.order,
            active_time: data.active_time,
          });
          setListIds(data.image_ids);

          // Initialize imageObjects from existing data if available
          if (data.image_ids && data.image_ids.length > 0) {
            const initialImageObjects = data.image_ids.map((id, index) => ({
              id: id,
              active_time: data.active_time[index].toString() || "",
              order: data.order[index].toString() || "",
            }));
            setImageObjects(initialImageObjects);
          }
        }
      });
    }
  }, []);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if (formData.images.length + files.length > MAX_IMAGES) {
      toast({
        title: "Too many images",
        description: `You can only upload a maximum of ${MAX_IMAGES} images`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const oversizedFiles = [];
    const invalidTypeFiles = [];

    const validFiles = files.filter((file) => {
      const isValidType = validateFileType(file);
      const isValidSize = validateFileSize(file);

      if (!isValidType) {
        invalidTypeFiles.push(file.name);
        return false;
      }

      if (!isValidSize) {
        oversizedFiles.push(file.name);
        return false;
      }

      return true;
    });

    if (oversizedFiles.length > 0) {
      toast({
        title: "Files too large",
        description: `${oversizedFiles.length} image(s) exceed the 3MB limit`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (invalidTypeFiles.length > 0) {
      toast({
        title: "Invalid file type",
        description: "Only JPEG, JPG, and PNG images are allowed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (validFiles.length > 0) {
      // Set loading state to true before starting uploads
      setImageLoading(true);

      let completedUploads = 0;

      // For each valid file, make an API call to upload and get ID
      validFiles.forEach(async (file) => {
        let params = {
          image: file,
          active_time: "",
          order: "",
        };
        Fetch(`kiosk/experience-media/`, params, {
          inFormData: true,
          method: "post",
        })
          .then((res) => {
            if (res.status) {
              const imageId = res.data.id;

              setFormData((prev) => {
                const newImages = [...prev.images, file];
                const newActiveTime = [...prev.active_time, ""];
                const newOrder = [...prev.order, ""];

                return {
                  ...prev,
                  images: newImages,
                  active_time: newActiveTime,
                  order: newOrder,
                };
              });

              // Add to imageObjects array
              setImageObjects((prev) => [
                ...prev,
                { id: imageId, active_time: "", order: "" },
              ]);
            }

            // Increment completed uploads counter
            completedUploads++;

            // If all uploads are completed, set loading to false
            if (completedUploads === validFiles.length) {
              setImageLoading(false);
            }
          })
          .catch(() => {
            // In case of errors, ensure loading state is reset
            completedUploads++;
            if (completedUploads === validFiles.length) {
              setImageLoading(false);
            }
          });
      });
    }
  };

  const handleRemoveItem = (index) => {
    const newImages = [...formData.images];
    const newActiveTime = [...formData.active_time];
    const newOrder = [...formData.order];

    newImages.splice(index, 1);
    newActiveTime.splice(index, 1);
    newOrder.splice(index, 1);

    // Also remove from imageObjects
    const newImageObjects = [...imageObjects];
    if (index < newImageObjects.length) {
      newImageObjects.splice(index, 1);
      setImageObjects(newImageObjects);
    }

    if (index < listIds.length) {
      const removedId = listIds[index];
      const updatedDeleteAds = listIds.filter((_, i) => i !== index);
      setListIds(updatedDeleteAds);

      setdeleteAds((prev) => [...prev, removedId]);
    }

    setFormData({
      ...formData,
      images: newImages,
      active_time: newActiveTime,
      order: newOrder,
    });
  };

  const handleOrderChange = (index, value) => {
    // Ensure value is not empty or invalid
    if (value === "" || isNaN(value)) {
      const newOrder = [...formData.order];
      newOrder[index] = ""; // Temporarily keep it empty
      setFormData({
        ...formData,
        order: newOrder,
      });
      return;
    }

    const numValue = parseInt(value, 10);

    if (numValue < 1) {
      toast({
        title: "Invalid order value",
        description: `Order must be atleast 1`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    const newOrder = [...formData.order];
    newOrder[index] = numValue.toString();

    setFormData({
      ...formData,
      order: newOrder,
    });

    // Update imageObjects as well
    if (index < imageObjects.length) {
      const updatedObjects = [...imageObjects];
      updatedObjects[index] = {
        ...updatedObjects[index],
        order: numValue.toString(),
      };
      setImageObjects(updatedObjects);
    }
  };

  const handleActiveTimeChange = (index, value) => {
    // Only allow positive integers
    const numValue = parseInt(value, 10);
    if (isNaN(numValue) || numValue <= 0) {
      if (value === "") {
        // Allow empty string during typing
        const newActiveTime = [...formData.active_time];
        newActiveTime[index] = value.toString();
        setFormData({
          ...formData,
          active_time: newActiveTime,
        });
        
        if (index < imageObjects.length) {
          const updatedObjects = [...imageObjects];
          updatedObjects[index] = {
            ...updatedObjects[index],
            active_time: value,
          };
          setImageObjects(updatedObjects);
        }
      }
      return;
    }
    
    if (value.length > 5) return;
    const newActiveTime = [...formData.active_time];
    newActiveTime[index] = numValue.toString();
  
    setFormData({
      ...formData,
      active_time: newActiveTime,
    });
  
    if (index < imageObjects.length) {
      const updatedObjects = [...imageObjects];
      updatedObjects[index] = {
        ...updatedObjects[index],
        active_time: numValue.toString(),
      };
      setImageObjects(updatedObjects);
    }
  };

  useEffect(() => {
    // Check overview tab
    const overviewComplete = 
      formData.version.trim() !== "" && 
      formData.description.trim() !== "";
    
    const instructionsComplete = 
      formData.workflow_instructions.trim() !== "" && 
      formData.system_instructions.trim() !== "" && 
      Object.keys(yamlErrors).length === 0;
    
    // Check media tab
    const mediaComplete = 
      formData.images.length > 0 && 
      !formData.active_time.some(time => time === "") && 
      !formData.order.some(order => order === "");
    
    setFormComplete({
      overview: overviewComplete,
      instructions: instructionsComplete,
      media: mediaComplete
    });
  }, [formData, yamlErrors]);

  const isSubmitDisabled = () => {
    return !(formComplete.overview && formComplete.instructions && formComplete.media);
  };

  const onSubmit = () => {
    setisLoading(true);
  
    let hasStepOneErrors = !formData.version.trim() || !formData.description.trim();
    let hasStepTwoErrors = !validateYaml(formData.workflow_instructions, "workflow_instructions") || 
                           !validateYaml(formData.system_instructions, "system_instructions");
    
    // If step 1 errors exist
    if (hasStepOneErrors) {
      toast({
        title: "Validation Error",
        description: "Please check all the input fields in Metadata section",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setisLoading(false);
      setTabIndex(0);
      return;
    }
  
    // If step 2 errors exist
    if (hasStepTwoErrors) {
      toast({
        title: "YAML Error",
        description: "Please fix the YAML syntax errors in Instructions section",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setisLoading(false);
      setTabIndex(1);
      return;
    }
  
    const hasEmptyActiveTime = formData.active_time.some((time) => time === "");
   const hasEmptyOrder = formData.order.some((order) => order === "");
  
  const orderValues = formData.order
  .filter(order => order !== "")
  .map(order => Number(order));  
  const uniqueOrderValues = new Set(orderValues);


  const hasDuplicateOrders = uniqueOrderValues.size !== orderValues.length;
    if (hasEmptyActiveTime) {
      toast({
        title: "Missing active time",
        description: "Please provide active time for all media files",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setisLoading(false);
      setTabIndex(2);
      return;
    }
    
    if (hasEmptyOrder) {
      toast({
        title: "Missing Order",
        description: "Please provide order for all media files",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setisLoading(false);
      setTabIndex(2);
      return;
    }
    
    if (hasDuplicateOrders) {
      toast({
        title: "Duplicate order values",
        description: "Each image must have a unique order value",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setisLoading(false);
      setTabIndex(2);
      return;
    }
    
    // If no errors, proceed with submission
    let params = { ...formData, ads_data: JSON.stringify(imageObjects) };
    if (!addForm) {
      params = {
        ...formData,
        delete_ads: deleteAds,
        ads_data: JSON.stringify(imageObjects),
      };
    }
    
    Fetch(`kiosk/experiences/${addForm ? "" : catalogueId + "/"}`, params, {
      inFormData: true,
      method: addForm ? "post" : "put",
    }).then((res) => {
      if (res.status) {
        setisLoading(false);
        toast({
          title: addForm ? "Experience Added" : "Experience Updated",
          description: addForm
            ? "Your experience has been successfully added."
            : "Your experience details have been successfully updated.",
          status: "success",
          duration: 500,
          isClosable: false,
        });
  
        setFormData({
          version: "",
          description: "",
          workflow_instructions: "",
          system_instructions: "",
          is_published: false,
          images: [],
          active_time: [],
          order: [],
        });
        setImageObjects([]);
        setTimeout(() => {
          history.push({
            pathname: "/admin/experience",
          });
        }, 500);
      } else {
        // Handle API validation errors by navigating to the appropriate tab
        handleNewError(res.data);
        setisLoading(false);
        
        // Check which fields have errors and navigate to the appropriate tab
        const errorFields = Object.keys(res.data || {});
        
        if (errorFields.some(field => ['version', 'description'].includes(field))) {
          setTabIndex(0);
        } else if (errorFields.some(field => [ 'system_instructions', 'workflow_instructions'].includes(field))) {
          setTabIndex(1);
        } else {
          // For any other errors (likely media-related)
          setTabIndex(2);
        }
      }
    });
  };
  
  const { handleSubmit, errors, handleNewError } = FormC({
    values: { ...formData },
    onSubmit,
  });
  
  useEffect(() => {
    if(errors?.version || errors?.description){
      setTabIndex(0);
    } else if(errors?.workflow_instructions || errors?.system_instructions){
      setTabIndex(1);
    }
  }, [errors]);

  return (
    <Box p={5} pt={{ base: "120px", md: "75px", lg: "80px" }}>
      <Flex align="end" justify="space-between" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">{addForm ? "Add Experience" : "Edit Experience"}</Text>
        <Button
          colorScheme="blue"
          isLoading={isLoading}
          onClick={handleSubmit}
          isDisabled={isSubmitDisabled()}
        >
          {addForm ? "Submit" : "Update"}
        </Button>
      </Flex>

      <Tabs variant="enclosed" colorScheme="blue" index={tabIndex} onChange={setTabIndex}>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Instructions</Tab>
          <Tab>Media Upload</Tab>
        </TabList>

        <TabPanels>
          {/* Overview Tab */}
          <TabPanel>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Overview
            </Text>
            <FormControl isRequired isInvalid={errors?.version}>
              <FormLabel>Version</FormLabel>
              <InputField
                name="version"
                value={formData.version}
                onChange={handleChange}
                errorText={errors.version}
              />
            </FormControl>
            <FormControl mt={4} isRequired >
              <FormLabel>Description</FormLabel>
              <InputField
                type="textarea"
                name="description"
                value={formData.description}
                onChange={handleChange}
                errorText={errors?.description}
              />
            </FormControl>
            <Stack direction="row" alignItems="center" mt={6} mb={4}>
              <Text
                fontSize="sm"
                fontWeight="bold"
                errorText={errors?.is_published}
              >
                Publish
              </Text>
              <Switch
                isChecked={formData.is_published}
                sx={{
                  "& .chakra-switch__track": {
                    bg: formData.is_published ? "blue.500" : "gray.400",
                    border: "1px solid gray",
                  },
                }}
                onChange={(e) =>
                  setFormData({ ...formData, is_published: e.target.checked })
                }
              />
            </Stack>
          </TabPanel>

          {/* Instructions Tab */}
          <TabPanel>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Instructions
            </Text>

            <FormControl
              mt={4}
              isInvalid={errors?.system_instructions || yamlErrors?.system_instructions}
              border="1px solid gray"
              p={2}
            >
              <FormLabel
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                System Instructions
                <IconButton
                  ml={2}
                  icon={
                    isEditorFullScreen.system ? <CloseIcon /> : <ExpandIcon />
                  }
                  size="sm"
                  onClick={() => toggleFullScreen("system")}
                  aria-label={isEditorFullScreen.system ? "Minimize" : "Maximize"}
                />
              </FormLabel>

              {isEditorFullScreen.system ? (
                <Modal isOpen={true} onClose={() => toggleFullScreen("system")}>
                  <ModalOverlay
                    bg="rgba(0, 0, 0, 0.5)"
                    backdropFilter="blur(5px)"
                  />
                  <ModalContent maxWidth="70vw" maxHeight="90vh">
                    <ModalHeader>System Instructions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Editor
                        height="calc(80vh - 50px)"
                        language="yaml"
                        value={formData.system_instructions}
                        onChange={(value) =>
                          handleYamlChange(value, "system_instructions")
                        }
                        options={{
                          minimap: { enabled: true },
                          automaticLayout: true,
                          formatOnPaste: true,
                          formatOnType: true,
                        }}
                      />
                    </ModalBody>
                  </ModalContent>
                </Modal>
              ) : (
                <Editor
                  height="100px"
                  language="yaml"
                  value={formData.system_instructions}
                  onChange={(value) =>
                    handleYamlChange(value, "system_instructions")
                  }
                  options={{
                    minimap: { enabled: false },
                    automaticLayout: true,
                    formatOnPaste: true,
                    formatOnType: true,
                  }}
                />
              )}
              <FormErrorMessage>{errors?.system_instructions || yamlErrors?.system_instructions}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={errors?.workflow_instructions || yamlErrors?.workflow_instructions}
              border="1px solid gray"
              p={2}
            >
              <FormLabel
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Workflow Instructions
                <IconButton
                  ml={2}
                  icon={
                    isEditorFullScreen.workflow ? <CloseIcon /> : <ExpandIcon />
                  }
                  size="sm"
                  onClick={() => toggleFullScreen("workflow")}
                  aria-label={
                    isEditorFullScreen.workflow ? "Minimize" : "Maximize"
                  }
                />
              </FormLabel>
              {isEditorFullScreen.workflow ? (
                <Modal isOpen={true} onClose={() => toggleFullScreen("workflow")}>
                  <ModalOverlay
                    bg="rgba(0, 0, 0, 0.5)"
                    backdropFilter="blur(3px)"
                  />
                  <ModalContent maxWidth="90vw" maxHeight="90vh">
                    <ModalHeader>Workflow Instructions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Editor
                        height="calc(80vh - 50px)"
                        language="yaml"
                        value={formData.workflow_instructions}
                        onChange={(value) =>
                          handleYamlChange(value, "workflow_instructions")
                        }
                        options={{
                          minimap: { enabled: true },
                          automaticLayout: true,
                          formatOnPaste: true,
                          formatOnType: true,
                        }}
                      />
                    </ModalBody>
                  </ModalContent>
                </Modal>
              ) : (
                <Editor
                  height="100px"
                  language="yaml"
                  value={formData.workflow_instructions}
                  onChange={(value) =>
                    handleYamlChange(value, "workflow_instructions")
                  }
                  options={{
                    minimap: { enabled: false },
                    automaticLayout: true,
                    formatOnPaste: true,
                    formatOnType: true,
                  }}
                />
              )}

              <FormErrorMessage>{errors.workflow_instructions || yamlErrors.workflow_instructions}</FormErrorMessage>
            </FormControl>
          </TabPanel>

          {/* Media Upload Tab */}
          <TabPanel>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Media Upload
            </Text>
            <Button
              onClick={() => document.getElementById("fileInput").click()}
              colorScheme="blue"
              isLoading={imageLoading}
            >
              Add Media
            </Button>
            <Input
              type="file"
              id="fileInput"
              hidden
              accept="image/jpeg,image/jpg,image/png"
              multiple
              onChange={handleFileChange}
            />
            <Table variant="simple" mt={4}>
              <Thead>
                <Tr>
                  <Th fontWeight="bold" color={'black'}>File</Th>
                  <Th fontWeight="bold" color={'black'}>Order</Th>
                  <Th  fontWeight="bold" color={'black'}>Active Time (Seconds)</Th>
                  <Th  fontWeight="bold" color={'black'}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {formData.images.map((file, index) => (
                  <Tr key={index}>
                    <Td>
                      <Image
                        src={
                          typeof file === "string"
                            ? file
                            : URL.createObjectURL(file)
                        }
                        style={{
                          textColor: "white",
                        }}
                        alt={`preview-${index}`}
                        boxSize="100px"
                        objectFit="contain"
                      />
                    </Td>
                    <Td>
                      <NumberInput
                        min={1}
                        max={99999}
                        type="number"
                        value={formData.order[index]}
                        onChange={(valueString) => {
                          // Limit to 5 digits
                          if (valueString.length <= 5) {
                            handleOrderChange(index, valueString);
                          }
                        }}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Td>

                    <Td>
                      <Input
                        type="number"
                        min="1"
                        max="99999"
                        name="active_time"
                        value={formData.active_time[index]}
                        onChange={(e) =>
                          handleActiveTimeChange(index, e.target.value)
                        }
                        onKeyDown={(e) => {
                          if (['e', '+', '-', '.'].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Enter time in milliseconds"
                      />
                    </Td>
                    <Td>
                      <IconButton
                        colorScheme="red"
                        icon={<DeleteIcon />}
                        onClick={() => handleRemoveItem(index)}
                        aria-label="Remove item"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TabbedExperienceForm;