// Chakra imports
import { FaPencilAlt } from "react-icons/fa";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Tooltip,
  Spinner,
  Input,
} from "@chakra-ui/react";
import Pagination from "common/Pagination";
import CardBody from "components/Card/CardBody.js";
import DeleteModal from "components/Modals/DeleteModal";
import Fetch from "../../common/fetch";
import React, { useEffect, useState } from "react";
import { PlusIco } from "components/Icons/menu";
import { useHistory, withRouter } from "react-router-dom";

import DataTable from "components/DataTable";
const countryOptions = [
  { value: "us", label: "United States" },
  { value: "ca", label: "Canada" },
  { value: "mx", label: "Mexico" },
  { value: "uk", label: "United Kingdom" },
  { value: "fr", label: "France" },
  { value: "de", label: "Germany" },
  { value: "it", label: "Italy" },
  { value: "es", label: "Spain" },
  { value: "jp", label: "Japan" },
  { value: "cn", label: "China" },
  { value: "br", label: "Brazil" },
  { value: "au", label: "Australia" },
  { value: "in", label: "India" },
  { value: "za", label: "South Africa" },
  { value: "ru", label: "Russia" },
];
function CatalogListing(props) {
  const history = useHistory();
  const color = useColorModeValue("blue.500", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const [catalogueData, setCatalogueData] = useState([]);
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  const getCatlogueData = (page, searchQuery) => {
    setLoading(true);
    let queryParams = `page=${page}`;

    if (searchQuery) queryParams += `&search=${searchQuery}`; // Append search query

    Fetch(`client/?${queryParams}`).then((res) => {
      if (res.status) {
        setCatalogueData(
          res?.data?.results?.map((items) => ({ ...items, actions: items }))
        );
        setTotalItems(res?.data?.count);
      }
      setLoading(false);
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Debounce API call
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCatlogueData(currentPage, searchQuery);
    }, 500); // Delays API call to prevent excessive requests

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, searchQuery]);

  const handleDeleteRequest = (id) => {
    Fetch(`client/${id}/`, null, { method: "delete" }).then((res) => {
      if (res.status) {
        getCatlogueData(currentPage, searchQuery);
        toast({
          title: "Client Deleted.",
          description: "We've deleted  Client successfully.",
          status: "success",
          duration: 3000,
          isClosable: false,
        });
      }
    });
  };
  const columns = [
    { accessor: "name", Header: "Name", width: 130 },
    { accessor: "prefix", Header: "Prefix", width: 130 },
    { accessor: "country", Header: "Country", width: 130 },
    { accessor: "database_name", Header: "Database Name", width: 130 },
    {
      accessor: "actions",
      Header: "Actions",
      width: 130,
      Cell: (cellValues) => (
        <Flex gap={2}>
          <DeleteModal
            name="Delete"
            title="Are you sure you want to delete this Client?"
            handleDelete={() => handleDeleteRequest(cellValues.value.id)}
          />

          <Tooltip label="Edit" hasArrow>
            <Button
              variant="outline"
              color={color}
              borderColor={color}
              colorScheme="blue"
              onClick={() => handleUpdateRequest(cellValues.value.id)}
            >
              <FaPencilAlt color={color} />
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
  ];
  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/clients/${id}`,
    });
  };
  return (
    <Box>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Flex p="6px 0px 22px 0px" justify={"space-between"}>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Clients
          </Text>
          <Stack direction={"row"} spacing={4}>
            <Button
              leftIcon={<PlusIco />}
              onClick={() => props.history.push("/admin/clients/add")}
              variant="outline"
              width={"120px"}
              h={"40px"}
              borderRadius={"8px"}
              border={"1px solid #0584FE"}
              p={"8px 14px 8px 14px"}
              fontWeight={"600"}
              fontSize={"14px"}
              lineHeight={"24px"}
              letterSpacing={"1%"}
              textAlign={"center"}
              pl={"15px"}
              textColor={"rgba(5, 132, 254, 1)"}
              borderColor={"rgba(5, 132, 254, 1)"}
            >
              New Client
            </Button>
          </Stack>
        </Flex>
        <Box mb={4} p={4} borderWidth="1px" borderRadius="md" bg="white">
          <Stack
            direction={"row"}
            spacing={4}
            align="center"
            justifyContent="flex-end"
          >
            <Input
              placeholder="Search clients..."
              value={searchQuery}
              onChange={handleSearchChange}
              width="250px"
            />
          </Stack>
        </Box>
        <CardBody
          border={"1px solid lightgrey"}
          padding={"15px"}
          borderRadius="5px"
          marginBottom="20px"
          p={0}
        >
          {loading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="blue.500" />
            </Flex>
          ) : (
            <DataTable
              table={{ columns: columns, rows: catalogueData, loading }}
              path="/inbound/asn/create"
            />
          )}
        </CardBody>
      </Flex>
      {(totalItems > itemsPerPage  && !loading) && (
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
        />
      )}
    </Box>
  );
}

export default withRouter(CatalogListing);
