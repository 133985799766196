import React, { Component } from "react";

import SignIn from "views/Pages/SignIn.js";

import {
   StoresIco,
  KiosksIcon,
  ExperienceIcon,
  SessionIcon
} from "./components/Icons/menu";
import ClientListing from "views/Dashboard/ClientListing";
import KioskListing from "views/Dashboard/ClientListing";
import sessionLogs from "views/Dashboard/SessionLogs";
import NewExperience from "views/Dashboard/NewExperience";

import ForgotPassword from "views/Pages/ForgotPassword";
import ExperienceLisiting from "views/Dashboard/ExperienceLisiting";

var dashRoutes = [
  {
    name: "",
    category: "account",
    state: "pageCollapse",
    views: [
      // {
      //   path: "/catalog",
      //   name: "Catalog Details",
      //   icon: <CatalogIco />,
      //   component: CatalogListing,
      //   layout: "/admin",
      // },
      // {
      //   path: "/categories",
      //   name: "Categories",
      //   icon: <CategoriesIco />,
      //   component: CategoriesListing,
      //   layout: "/admin",
      // },
      {
        path: "/clients",
        name: "Manage Client",
        icon: <StoresIco />,
        component: ClientListing,
        layout: "/admin",
      },
      {
        path: "/experience",
        name: "Manage Experience",
        icon: <ExperienceIcon />,
        component:ExperienceLisiting ,
        layout: "/admin",
      },
      {
        path: "/kiosks",
        name: "Manage Kiosk",
        icon: <KiosksIcon />,
        component:KioskListing ,
        layout: "/admin",
      },
      
      {
        path: "/session-logs",
        name: "Sesssion Logs",
        icon: <SessionIcon />,
        component:sessionLogs ,
        layout: "/admin",
      },
      {
        path: "/experience/:id",
        name: "Add Template",
        removeSidebar: true,
        component: NewExperience,
        layout: "/admin",
      },
      // {
      //   path: "/template",
      //   name: "Template",
      //   icon: <ObjectivesIco />,
      //   component: Template,
      //   layout: "/admin",
      // },
      // {
      //   path: "/template/:id",
      //   name: "Add Template",
      //   removeSidebar: true,
      //   component: NewTemplate,
      //   layout: "/admin",
      // },
      // {
      //   path: "/setting",
      //   name: "Settings",
      //   icon: <SettingsIco />,
      //   component: Settings,
      //   layout: "/admin"
      // },
      // {
      //   path: "/",
      //   name: "Translations",
      //   icon: <TranslationsIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Objectives",
      //   icon: <ObjectivesIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Conversations",
      //   icon: <ConversationsIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "User Experiences",
      //   icon: <UserExperiencesIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Nodes",
      //   icon: <StoresIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Analytics",
      //   icon: <AnalyticsIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      {
        path: "/signin",
        name: "Sign In",
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/reset-password/:id",
        name: "Forgot Password",
        component: ForgotPassword,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
