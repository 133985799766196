export const  formatLastModified = (last_modified) => {
    const date = new Date(last_modified);
    
    // Convert to the desired format: YYYY-MM-DD HH:MM:SS UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
    // Construct the final string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
  }

export  const onKeyPress = (evt) => {
      var theEvent = evt || window.event;
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
      
      var regex = /^[a-zA-Z\s\b]+$/;// Only alphabets (A-Z, a-z) allowed
      
      if (!regex.test(key)) {
          theEvent.returnValue = false;
          if (theEvent.preventDefault) theEvent.preventDefault();
      }
  };

  export const preventChanges =(e)=>{
   e.preventDefault();
  return;
       
  }

  