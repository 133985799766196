// Chakra imports
import { FaPencilAlt } from "react-icons/fa";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Tooltip,
  FormControl,
  FormLabel,
  HStack,
  Badge,
  Spinner,
  Input
} from "@chakra-ui/react";
// Custom components
import Pagination from "common/Pagination";
import CardBody from "components/Card/CardBody.js";
import DeleteModal from "components/Modals/DeleteModal";
import Fetch from "../../common/fetch";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { PlusIco } from "components/Icons/menu";
import { useHistory, withRouter } from "react-router-dom";
import DataTable from "components/DataTable";
import SearchableSelect from "components/CustomSearch";

function CatalogListing(props) {
  const history = useHistory();
  const color = useColorModeValue("blue.500", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const [catalogueData, setCatalogueData] = useState([]);
  const [clients, setClients] = useState([]);
  const [experience, setExperience] = useState([]);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  // Add filter states
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  
  // Status options for the searchable select
  const statusOptions = [
    { value: "provisioned", label: "Provisioned" },
    { value: "unprovisioned", label: "Unprovisioned" },
  ];

  const getCatlogueData = (
    page,
    selectedClient,
    selectedExperience,
    selectedStatus,
    searchQuery
  ) => {

    let queryParams = `page=${page}`;
    if (selectedClient) queryParams += `&client_id=${selectedClient}`;
    if (selectedExperience) queryParams += `&experience_id=${selectedExperience}`;
    if (selectedStatus) queryParams += `&status=${selectedStatus}`;
    if (searchQuery) queryParams += `&search=${searchQuery}`; // Add search query
    setApiLoading(true);
    Fetch(`kiosk/?${queryParams}`).then((res) => {
      if (res.status) {
        setApiLoading(false);
        setCatalogueData(
          res?.data?.results?.map((items) => {
            return { ...items, actions: items };
          })
        );
        setTotalItems(res?.data?.count);
      }
    });
  };
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); 
  };
  
  const handleDelete = useCallback((id) => {
    handleDeleteRequest(id);
  }, [currentPage,   selectedClient,
    selectedExperience,
    selectedStatus,
    searchQuery ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCatlogueData(
        currentPage,
        selectedClient,
        selectedExperience,
        selectedStatus,
        searchQuery
      );
    }, 500); 
  
    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, selectedClient, selectedExperience, selectedStatus, searchQuery]);

  useEffect(() => {
    fetchClients();
    fetchExperiences();
  }, []);

  const handleFilterChange = () => {
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleDeleteRequest = (id) => {
    Fetch(`kiosk/${id}/`, null, { method: "delete" }).then((res) => {
      if (res.status) {
        setCurrentPage(1);
        getCatlogueData(
          currentPage,
          selectedClient,
          selectedExperience,
          selectedStatus,
          searchQuery
        );
        toast({
          title: "Kiosk Deleted.",
          description: "We've deleted kiosk successfully.",
          status: "success",
          duration: 3000,
          isClosable: false,
        });
      }
    });
  };

  const fetchClients = () => {
    Fetch(`client/?page=0`).then((res) => {
      if (res.status) {
        const { data } = res;
        setClients(data);
      }
    });
  };

  const fetchExperiences = () => {
    Fetch(`kiosk/experiences/?page=0`).then((res) => {
      if (res.status) {
        const { data } = res;
        setExperience(data);
      }
    });
  };

  const clientOptions = clients.map(client => ({ value: client.id, label: client.name }));

  const experienceOptions = experience.map(exp => ({ value: exp.id, label: exp.version }));
  

  const columns = [
    {
      accessor: "client_id",
      Header: "Client Name",
      width: 200,
      Cell: ({ row }) => {
        const client = row.original.client_id;
        return client ? `${client.name}` : "";
      },
    },
    { accessor: "name", Header: "Name", width: 130 },

    { accessor: "serial_number", Header: "Serial Number", width: 130 },
    {
      accessor: "experience_id",
      Header: "Version",
      width: 200,
      Cell: ({ row }) => {
        const client = row.original.experience_id;
        return client ? `${client.version}` : "";
      },
    },
    {
      accessor: "status",
      Header: "Status",
      width: 130,
      Cell: ({ row }) => {
        const status = row.original.status;

        // Define colors for different statuses
        const statusColors = {
          provisioned: "green",
          unprovisioned: "red",
          pending: "yellow",
        };

        return (
          <Badge colorScheme={statusColors[status] || "gray"} variant="solid">
            {status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      accessor: "actions",
      Header: "Actions",
      width: 130,
      Cell: (cellValues) => (
        <Flex gap={2}>
          <DeleteModal
            name="Delete"
            title="Are you sure you want to delete this Kiosk?"
            handleDelete={() => handleDelete(cellValues.value.id)}
          />

          <Tooltip label="Edit" hasArrow>
            <Button
              variant="outline"
              color={color}
              borderColor={color}
              colorScheme="blue"
              onClick={() => handleUpdateRequest(cellValues.value.id)}
            >
              <FaPencilAlt color={color} />
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/kiosks/${id}`,
    });
  };

  return (
    <Box>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Flex p="6px 0px 22px 0px" justify={"space-between"}>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Kiosks
          </Text>
          <Stack direction={"row"} spacing={2}>
            <Button
              leftIcon={<PlusIco />}
              onClick={() => props.history.push("/admin/kiosks/add")}
              variant="outline"
              width={"120px"}
              h={"40px"}
              borderRadius={"8px"}
              border={"1px solid #0584FE"}
              p={"8px 14px 8px 14px"}
              fontWeight={"600"}
              fontSize={"14px"}
              lineHeight={"24px"}
              letterSpacing={"1%"}
              textAlign={"center"}
              pl={"15px"}
              textColor={"rgba(5, 132, 254, 1)"}
              borderColor={"rgba(5, 132, 254, 1)"}
            >
              New Kiosk
            </Button>
            <Button
              leftIcon={<PlusIco />}
              onClick={() => props.history.push("/admin/kiosks/assign")}
              variant="outline"
              width={"130px"}
              h={"40px"}
              borderRadius={"8px"}
              border={"1px solid #0584FE"}
              p={"8px 14px 8px 14px"}
              fontWeight={"600"}
              fontSize={"14px"}
              lineHeight={"24px"}
              letterSpacing={"1%"}
              textAlign={"center"}
              ml="10"
              textColor={"rgba(5, 132, 254, 1)"}
              borderColor={"rgba(5, 132, 254, 1)"}
            >
              Assign Kiosk
            </Button>
          </Stack>
        </Flex>

        <Box mb={4} p={4} borderWidth="1px" borderRadius="md" bg="white">
          <HStack spacing={10} wrap="wrap" justifyContent="flex-end">
            <FormControl minW="200px" maxW="250px">
              <FormLabel fontSize="sm">Client</FormLabel>
              <SearchableSelect
                options={clientOptions}
                value={selectedClient}
                onChange={(value) => {
                  setSelectedClient(value);
                  handleFilterChange();
                }}
                placeholder="All Clients"
              />
            </FormControl>

            <FormControl minW="200px" maxW="250px">
              <FormLabel fontSize="sm">Experience</FormLabel>
              <SearchableSelect
                options={experienceOptions}
                value={selectedExperience}
                onChange={(value) => {
                  setSelectedExperience(value);
                  handleFilterChange();
                }}
                placeholder="All Experience"
              />
            </FormControl>

            <FormControl minW="200px" maxW="250px">
              <FormLabel fontSize="sm">Status</FormLabel>
              <SearchableSelect
                options={statusOptions}
                value={selectedStatus}
                onChange={(value) => {
                  setSelectedStatus(value);
                  handleFilterChange();
                }}
                placeholder="Select Status"
              />
            </FormControl>

            <FormControl minW="200px" maxW="250px">
              <FormLabel fontSize="sm">Search</FormLabel>
              <Input
                placeholder="Search kiosks..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </FormControl>
          </HStack>
        </Box>

        <CardBody
          border={"1px solid lightgrey"}
          padding={"15px"}
          borderRadius="5px"
          marginBottom="20px"
          p={0}
        >
          {apiLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="blue.500" />
            </Flex>
          ) : (
            <DataTable
              table={{ columns: columns, rows: catalogueData, apiLoading }}
              path="/inbound/asn/create"
            />
          )}
        </CardBody>
      </Flex>

      {(totalItems > itemsPerPage  && !apiLoading) && (
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
        />
      )}
    </Box>
  );
}

export default withRouter(CatalogListing);