import { FaPencilAlt } from "react-icons/fa";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Tooltip,
  Spinner,
  FormControl,
  FormLabel,
  Input
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import Pagination from "common/Pagination";
import CardBody from "components/Card/CardBody.js";
import DeleteModal from "components/Modals/DeleteModal";
import Fetch from "../../common/fetch";
import React, { useEffect, useState, useRef } from "react";
import { PlusIco } from "components/Icons/menu";
import { useHistory, withRouter } from "react-router-dom";
import DataTable from "components/DataTable";
import SearchableSelect from "components/CustomSearch";

function ExperienceListing(props) {
  const history = useHistory();
  const color = useColorModeValue("blue.500", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const [catalogueData, setCatalogueData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPublished, setIsPublished] = useState(""); // Default: Show all
  const [apiLoading, setApiLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  // Options for publish status filter
  const publishOptions = [
    { value: "true", label: "Published" },
    { value: "false", label: "Unpublished" }
  ];

  const getCatlogueData = (page, searchQuery, isPublished) => {
    let queryParams = `page=${page}`;

    if (searchQuery) queryParams += `&search=${searchQuery}`;
    if (isPublished !== "")
      queryParams += `${queryParams ? "&" : ""}is_published=${isPublished}`;

    setApiLoading(true);

    Fetch(`kiosk/experiences/?${queryParams}`)
      .then((res) => {
        if (res.status) {
          setApiLoading(false);
          setCatalogueData(
            res?.data?.results?.map((items) => {
              return { ...items, actions: items };
            })
          );
          setTotalItems(res?.data?.count);
        }
      }).catch(() => setApiLoading(false));
  };

  // Fetch experiences when searchQuery or isPublished changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCatlogueData(currentPage, searchQuery, isPublished);
    }, 500); // Debounce to prevent excessive API calls

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, isPublished, currentPage]);

  const handleDeleteRequest = (id) => {
    Fetch(`kiosk/experiences/${id}/`, null, { method: "delete" }).then(
      (res) => {
        if (res.status) {
          getCatlogueData(currentPage,searchQuery, isPublished);
          toast({
            title: "Experience Deleted.",
            description: "We've deleted Experience successfully.",
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        }
      }
    );
  };
  
  const columns = [
    { accessor: "version", Header: "Version", width: 130 },
    {
      accessor: "description",
      Header: "Description",
      width: 130,
    },
    {
      accessor: "is_published",
      Header: "Published",
      width: 130,
      Cell: ({ value }) => (value ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />
      ),
    },
    {
      accessor: "actions",
      Header: "Actions",
      width: 130,
      Cell: (cellValues) => (
        <Flex gap={2}>
          <DeleteModal
            name="Delete"
            title="Are you sure you want to delete this Experience?"
            handleDelete={() => handleDeleteRequest(cellValues.value.id)}
          />

          <Tooltip label="Edit" hasArrow>
            <Button
              variant="outline"
              color={color}
              borderColor={color}
              colorScheme="blue"
              onClick={() => handleUpdateRequest(cellValues.value.id)}
            >
              <FaPencilAlt color={color} />
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
  ];
  
  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/experience/${id}`,
    });
  };
  
  return (
    <Box>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Flex p="6px 0px 22px 0px" justify={"space-between"}>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Experience
          </Text>
          <Stack direction={"row"} spacing={4}>
            <Button
              leftIcon={<PlusIco />}
              onClick={() => props.history.push("/admin/experience/add")}
              variant="outline"
              width={"140px"}
              h={"40px"}
              borderRadius={"8px"}
              border={"1px solid #0584FE"}
              p={"8px 14px 8px 14px"}
              fontWeight={"600"}
              fontSize={"14px"}
              lineHeight={"24px"}
              letterSpacing={"1%"}
              textAlign={"center"}
              pl={"15px"}
              textColor={"rgba(5, 132, 254, 1)"}
              borderColor={"rgba(5, 132, 254, 1)"}
            >
              New Experience
            </Button>
          </Stack>
        </Flex>

        <Box mb={4} p={4} borderWidth="1px" borderRadius="md" bg="white">
          <Stack spacing={4} direction="row" justify="flex-end">
            {/* Search Input */}
            <FormControl maxW="250px">
              <FormLabel fontSize="sm">Search</FormLabel>
              <Input
                placeholder="Search experiences..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </FormControl>

            {/* Searchable Select for Publish Status */}
            <FormControl maxW="250px">
              <FormLabel fontSize="sm">Publish Status</FormLabel>
              <SearchableSelect
                options={publishOptions}
                value={isPublished}
                onChange={setIsPublished}
                placeholder="All"
              />
            </FormControl>
          </Stack>
        </Box>

        <CardBody
          border={"1px solid lightgrey"}
          padding={"15px"}
          borderRadius="5px"
          marginBottom="20px"
          p={0}
        >
          {apiLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="blue.500" />
            </Flex>
          ) : (
            <DataTable
              table={{ columns: columns, rows: catalogueData, loading }}
              path="/inbound/asn/create"
            />
          )}{" "}
        </CardBody>
      </Flex>

      {(totalItems > itemsPerPage  && !apiLoading) && (
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
        />
      )}
    </Box>
  );
}

export default withRouter(ExperienceListing);