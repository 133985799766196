import { useState, useEffect, FormEvent } from 'react'

export const validation = (data) => {
  let errors = {}
  for (const property in data) {
    if (data[property]?.length && Array.isArray(data[property])) {
      errors[property] = data[property]?.length ? '' : inputValidation(data, property)
      if (!errors[property]?.length) {
        delete errors[property]
      }
    } else {
      errors = {
        ...errors,
        ...inputValidation(data, property)
      }
    }

  }
  return errors
}
export const onKeyPress = (evt, reg) => {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  var regex = reg ? reg : /^[0-9\b]+$/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};
const inputValidation = (data, property) => {
  const errors = {}

  // if (typeof data[property] === "string" && data[property].trim().length >= 50) {
  //   errors[property] = "Value must be less than 50.";
  // }
  if (data[property] === null || data[property] === undefined || !data[property].toString().length)
    errors[property] = `Please ${property.includes('photo') ? 'upload' : property === 'categories'
      ? 'select' : 'enter'} ${property === 'email' ? 'email address.' : property?.split('_') ? property?.split('_').join(' ') + '.' : property + '.'
      }`
  if (property === 'email' && data[property]?.length) {
    if (ValidateEmailAddress(data[property])) {
      errors[property] = ValidateEmailAddress(data[property]);
    }
  }
 
   if( property === 'experience_id'){
    errors[property] = `Please enter experience ID.`

   } 
  if ( (property === 'experience' || property === "country" || property === 'client' || property === 'client_id' || property === 'provision_code' || property === 'experience_id' || property === "client_id" )  && !data[property]?.length) {
     if(property === 'provision_code'){
      errors[property] = `Please select provision code`
    }else if(property === 'client_id'){
      errors[property] = `Please select client`
    }else if(property === 'experience_id'){
      errors[property] = `Please select experience`
    }
     else if(!data[property]?.length){
      errors[property] = `Please select ${property}`

    }

  }
  if ( property === 'experience_id' && data[property]?.length) {
    delete errors[property];
  }

  if ( property === 'client_id' && data[property]?.length) {
    delete errors[property];
  }
  if (property.includes('phone') && data[property]?.length) {
    if (data[property]?.length < 10) {
      errors[property] = 'Phone number must have at least 10 digits.';
    }
  }
  if ((property === 'password' || property === 'new_password') && data[property].length) {
    if (passwordCheck(data[property])) {
      errors[property] = passwordCheck(data[property]);
    }
  }
  if (property === 'password2' && data['password2']?.length) {
    if (data['password2'] !== data['password']) {
      errors['password2'] = 'Password does not match. Please make sure they match.'
    } else {
      delete errors['password2']
    }
  }
  if (property === 'confirm_new_password' && data['confirm_password']?.length) {
    if (data['confirm_new_password'] !== data['new_password']){
      errors['confirm_new_password'] = 'Password does not match. Please make sure they match.'
    }else {
      delete errors['confirm_new_password']
    }
  }
  if (property === 'hr_email' && data[property]?.length) {
    if (ValidateEmailAddress(data[property])) {
      errors[property] = ValidateEmailAddress(data[property]);
    }
  }
  if(property === 'follow_up_message_time' || property == 'follow_up_text_message' || property == 'greet_text_message' || property == 'session_end'  || property == 'session_end_timer' && data[property]?.length){
    if (validationSettings(data[property])) {
      errors[property] = validationSettings(data[property]);
    }
  }
  if(property === "frame_to_send_greet" && data[property]?.length){
    if(data[property]<3){
      errors[property] = `minimum value must be greater than two.`

    }
  }
  if (property === 'threshold' && data[property]?.length) {
           if(data[property]<0.01 || data[property]>0.05){
            errors[property] = `Please enter the correct value`

           }
  }
  if (property === 'description' && data[property]?.length) {
    if (data[property]?.length >255) {
      errors[property] = 'Input cannot exceed 255 characters.';
    }
  }
  if(property == 'article_and_review_ids' || property === "article_reviews" || property === "attribute" || property === "attributes"){
    delete errors[property]; // Delete the entry if it exists

  }
  return errors
}
export const passwordCheck = (password) => {
  if (password.length < 8) return 'At least 8 characters'
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^\w\d\s]).{8,}$/
  if (!regex.test(password)) return 'Your password is incorrect. Please try again'
}
export const ValidateEmailAddress = (emailString) => {
  if (!emailString) return 'Please enter email'
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (!regex.test(emailString)) return 'Your email is incorrect. Please try again'
}
export const validationSettings =(settings)=>{
  if(settings<5){
    return 'minimnum value must be greater than four.'
  }
  if(settings>=2147483647){
    return 'minimnum value must be lesser than 2147483647.'

  }
}
export const FormC = ({ values, removeValidValue, onSubmit, onSubmitError }) => {
  
  const [err, setErr] = useState({});
  const [stateParam, setStateParam] = useState({ ...values });
  useEffect(() => {
    if ((values && JSON.stringify(values)) !== JSON.stringify(stateParam)) {
      setStateParam(values);
    }
  }, [values]);
  const removeAllError = () => {
    setErr({})
  }
  const handleSubmit = (e) => {
    e?.preventDefault();
    const data = removeFormValidation(stateParam);
    const error = validation(data);
    setErr(error);
    if (!Object?.keys(error)?.length) {
      setErr({})
      onSubmit(e);
    } else {
      onSubmitError && onSubmitError(error);
      const err = Object.keys(error);
      if (err.length) {
        const input = document.querySelector(`input[name=${err[0]}]`);
        input?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  };
  const handleNewError = (error) => {
    if (error?.ads && error?.ads[0] && error?.ads[0]?.length > 0) {
      setErr({ images: error.ads[0] }); // Sirf pehla error message store hoga
      return;
  }
  if (error?.serial_number && error?.serial_number?.length > 0) {
    error?.serial_number.forEach(message => {
      setErr({ serial_number: message });
    });
    return;

  }

    if (error?.name &&  error.name && error?.name?.length > 0) {
      error?.name.forEach(message => {
        if(message){
          setErr({ name: message });

        }
      });
      return;

    }

    if (error?.experience_id && error?.experience_id?.length > 0) {
      error?.experience_id.forEach(message => {
        setErr({ experience_id: message });
      });
      return;

    }

    if (error?.client_id && error?.client_id?.length > 0) {
      error?.client_id.forEach(message => {
        setErr({ client_id: message });
      });
      return;

    }

    if (error?.email && error?.email.length > 0) {
      error?.email.forEach(message => {
        setErr({ email:  message });
      });
      return;
    }
    if (error.system_instructions  || error.workflow_instructions) {
      setErr(prevErrors => ({ 
        ...prevErrors,
        ...error
      }));
      return;
    }
    
    setErr(prevErrors => ({  ...error }));

  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const state = {
      ...stateParam,
      [name]: value,
    };
    setStateParam(state);
    if (value?.length) {
      const data = removeFormValidation({ [name]: value });
      if (!Object?.keys(data)?.length) {
        let error = validation(state);
        setErr(error);
      }
    }
  };
  const handleArrayChange = (e, type) => {
    const { name, value } = e?.target || {};
    let state = {
      [name]: value,
    };
    if (value?.length) {
      let error = validation(state);
      setErr({
        [type]: [error]
      });
    } else {
      setErr({});
    }
  }
  const handleChange = (e) => {
    const { name, value, type } = e?.target || {};
    
    let state = {
      [name]: value,
    };
    const data = removeFormValidation({ [name]: value });
    if (Object?.keys(data)?.length) {
      if (value?.length) {
        var stateparam = {
          ...state,
        }
        if (name === 'confirm_password') {
          stateparam = {
            ...stateparam,
            password: stateParam?.password
          }
        }
        let error = validation(stateparam);
        setErr(error);
      } else {
        setErr({});
      }
    }
  };
  const removeFormValidation = (stateUpdate) => {
    let d = { ...stateUpdate };
    if (removeValidValue?.length) {
      for (let name in d) {
        if (removeValidValue?.includes(name)) {
          delete d[name];
        }
      }
    }
    return d;
  };
  const obj = {
    handleBlur,
    removeFormValidation,
    handleChange,
    handleSubmit,
    handleNewError,
    handleArrayChange,
    removeAllError,
    errors: err,
  };
  return obj;
};