import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  List,
  ListItem
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Fetch from "../../common/fetch";
import { FormC } from "../../common/validate";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const SearchableSelect = ({ options, onChange, value, name, placeholder, error }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find(opt => opt.value === value) || null
  );
  const selectRef = useRef(null);
  
  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setSearchValue("");
    setIsOpen(false);
    onChange({
      target: {
        name: name,
        value: option.value
      }
    });
  };
  
  return (
    <Box position="relative" ref={selectRef}>
      <Input
        value={selectedOption ? selectedOption.label : searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          if (!isOpen) setIsOpen(true);
          if (selectedOption && e.target.value !== selectedOption.label) {
            setSelectedOption(null);
            onChange({
              target: {
                name: name,
                value: ""
              }
            });
          }
        }}
        onFocus={() => setIsOpen(true)}
        placeholder={placeholder || "Select an option"}
        borderRadius={"8px"}
        isInvalid={!!error}
      />
      
      {isOpen && (
        <List
          position="absolute"
          width="100%"
          bg="white"
          boxShadow="md"
          mt="2px"
          zIndex={10}
          maxH="200px"
          overflowY="auto"
          borderRadius="md"
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map(option => (
              <ListItem
                key={option.value}
                px={3}
                py={2}
                _hover={{ bg: "gray.100" }}
                cursor="pointer"
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </ListItem>
            ))
          ) : (
            <ListItem px={3} py={2} color="gray.500">
              No options found
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

function AssignKiosk(props) {
  const history = useHistory();
  const [isLoading, setisLoading] = useState(false);
  const [state, setState] = useState({
    provision_code:'',
    experience:'',
    client:''
  });
  const[provisionCodes, setProvisionCodes] = useState([]);
  const[clients, setClients] = useState([]);
  const[experience, setExperience] = useState([]);
  const toast = useToast();
  const onSubmit = () => {
    setisLoading(true);
    const requestBody = {
      ...state,
      experience_id: state.experience,
      client_id: state.client
    };

    Fetch(`kiosk/assign/`, requestBody, {
      method:"post",
    }).then((res) => {
      if (res.status) {
        setisLoading(false);
        toast({
          title: "Kiosk Assigned",
          description: "We've assigned the kiosk successfully.",
          status: 'success',
          duration: 1000,
          isClosable: false,
        });
        setState({
          provision_code: '',
          experience: '',
          client: ''
        });
        setTimeout(() => {
          history.push({
            pathname: "/admin/kiosks",
          });
        }, 500);
      } else {
        handleNewError({serial_number: res.data})
        setisLoading(false);
      }
    })
  };
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchProvisionCodes();
    fetchClients();
    fetchExperiences();
  }, []);

  const fetchProvisionCodes = () => {
    Fetch(`kiosk/provisioncodes/?is_used=false`).then((res) => {
      if (res.status) {
        const { data } = res
        setProvisionCodes(data.results);
      }
    });
  }
  
  const fetchClients = () => {
    Fetch(`client/?page=0`).then((res) => {
      if (res.status) {
        const { data } = res
        setClients(data);
      }
    });
  }
  
  const fetchExperiences = () => {
    Fetch(`kiosk/experiences/?page=0`).then((res) => {
      if (res.status) {
        const { data } = res
        setExperience(data);
      }
    });
  }

  const provisionCodeOptions = provisionCodes
    .filter((code) => !code.is_used)
    .map((code) => ({
      value: code.code,
      label: `${code.code} - ${code.code_serial_number}`
    }));
    
  const clientOptions = clients.map((client) => ({
    value: client.id,
    label: client.name
  }));
  
  const experienceOptions = experience.map((exp) => ({
    value: exp.id,
    label: exp.version
  }));

  const { handleSubmit, errors, handleNewError } = FormC({
    values: { ...state },
    onSubmit,
  });

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px", lg: "80px" }}
      bgColor={"white"}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <Stack direction="row" alignItems={'center'} width={"fit-content"}>
            <Badge
              fontSize={"16px"}
              bgColor={"#f8f8f8"}
              borderRadius={'8px'}
              padding={'4px 10px'}
              textColor={"#898989"}
              lineHeight={"32px"}
              mr={"10px"}
            >
              { "Assign Kiosk"}
            </Badge>
          </Stack>
          <Stack direction="row" spacing={4}>
            <NavLink to="/admin/kiosks">
              <Button
                variant="outline"
                textColor={"#0584FE"}
                borderColor={"#0584FE"}
              >
                Cancel
              </Button>
            </NavLink>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              {"Assign"} & Close
            </Button>
          </Stack>
        </div>
    
        <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"15px"}
          borderRadius={"8px"}
        >
          <Grid templateColumns='repeat(1, 1fr)' gap={3}>
            <GridItem w='100%'>
              <FormControl isInvalid={errors?.provision_code}>
                <FormLabel fontWeight="bold">Provision Code</FormLabel>
                <SearchableSelect
                  options={provisionCodeOptions}
                  value={state.provision_code}
                  onChange={onChange}
                  name="provision_code"
                  placeholder="Select a provision code"
                  error={errors?.provision_code}
                />
                <FormErrorMessage>{errors?.provision_code}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem w='100%'>
              <FormControl isInvalid={errors?.client}>
                <FormLabel fontWeight="bold">Client</FormLabel>
                <SearchableSelect
                  options={clientOptions}
                  value={state.client}
                  onChange={onChange}
                  name="client"
                  placeholder="Select a client"
                  error={errors?.client}
                />
                <FormErrorMessage>{errors?.client}</FormErrorMessage>
              </FormControl>
            </GridItem>
            
            <GridItem w='100%'>
              <FormControl isInvalid={errors?.experience}>
                <FormLabel fontWeight="bold">Experience</FormLabel>
                <SearchableSelect
                  options={experienceOptions}
                  value={state.experience}
                  onChange={onChange}
                  name="experience"
                  placeholder="Select an experience"
                  error={errors?.experience}
                />
                <FormErrorMessage>{errors?.experience}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </Box>
      </form>
    </Flex>
  );
}

export default AssignKiosk;