import React, { useState } from "react";
import { FormC } from "common/validate";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  Alert,
  AlertTitle,
  Image,
} from "@chakra-ui/react";
import Fetch from "../../common/fetch";
import CrossIcon from "../../assets/svg/cross-icon.svg";
import { withRouter } from "react-router-dom";

function SignIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [correctPassword, setCorrectPassword] = useState(true);
  const [loading,setLoading] = useState(false);
  const onSubmit = () => {
    setLoading(true);
    const url = "accounts/login/";

    const postData = {
      email: email,
      password: password,
    };

    Fetch(url, postData, { method: "post" }).then((res) => {
       if (res?.status) {
        const token = res.data.access;
        localStorage.refresh_token = res.data.refresh;
        localStorage.setItem("token", token);
        document.cookie = `token=${token}`;
        props.history.push("/admin/clients");

      } else {
        if(res?.err){
          props.history.push("/auth/signin");
           
        }
        setCorrectPassword(false);
      }
      setLoading(false);
    })
  };

   const handleKeyPress = (event) => {
     if (event.key === "Enter") {
       handleSubmit();
     }
   };
  const { handleSubmit, errors, handleNewError } = FormC({
    values: { password, email},
    onSubmit,
  });
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  return (
    <Flex position="relative" mb="0px" ml={"0px"}>
      <Flex
        minH={{ md: "850px" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
      >
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="8px"
            p="30px"
            pt="60px"
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 74px 100px 0px rgba(11, 24, 56, 0.05)",
              "unset"
            )}
          >
            <Text
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
              fontStyle={"Source Sans 3"}
            >
              Sign In
            </Text>
            <FormControl isInvalid={!!errors.email}                 mb="24px"
            >
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="auth"
                fontSize="sm"
                ms="4px"
                mb="5px"

                type="email"
                placeholder="Enter your email"
                borderRadius={"8px"}
                size="md"
                background={"#F8F8F8"}
                onKeyPress={handleKeyPress}
              />
              {errors.email && <Text color="red.500" fontSize="sm">{errors.email}</Text>}
              </FormControl>
              <FormControl isInvalid={!!errors.password}>

              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <Input
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="auth"
                fontSize="sm"
                ms="4px"
                borderRadius={"8px"}
                type="password"
                placeholder="Your password"
                size="md"
                mb="5px"

                background={"#F8F8F8"}
                onKeyPress={handleKeyPress}
              />
              { errors.password && <Text color="red.500" fontSize="sm">{errors.password}</Text>}
              {!correctPassword && (
                <Alert status="error" mt={"15px"} borderRadius={"5px"}>
                  <Image src={CrossIcon} w={"20px"} mr={"10px"} />
                  <AlertTitle fontWeight={"normal"}>
                    Invalid Email or Password
                  </AlertTitle>
                </Alert>
              )}
              <Button
                type="submit"
                fontSize="14px"
                fontWeight="600"
                w="100%"
                borderRadius={"8px"}
                h="52px"
                mt={"24px"}
                onClick={handleSubmit}
                colorScheme="blue"
                isLoading={loading}
              >
                SIGN IN
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            ></Flex>
          </Flex>
        </Flex>
        <Box
          overflowX="hidden"
          h="100%"
          w="100%"
          left="0px"
          position="absolute"
        >
          <Box
            w="100%"
            h="100%"
            bgSize="cover"
            opacity="0.8"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default withRouter(SignIn);
