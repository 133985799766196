// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useToast,
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  Image
} from "@chakra-ui/react";
import Pagination from "common/Pagination";
import CardBody from "components/Card/CardBody.js";
import Fetch from "../../common/fetch";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory, withRouter } from "react-router-dom";
import DataTable from "components/DataTable";
import downloadIcon from "../../assets/svg/download.svg"
import SearchableSelect from "components/CustomSearch";
import { formatLastModified } from "common/array";
function CatalogListing() {
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const [catalogueData, setCatalogueData] = useState([]);
  const [kiosks, setKiosks] = useState([]);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const [selectedClient, setSelectedClient] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  

  const getCatlogueData = (
    page,
    selectedClient,
  ) => {

    let queryParams = `page=${page}`;
    if (selectedClient) queryParams += `&kiosk=${selectedClient}`;
    setApiLoading(true);
    Fetch(`dashboard/session-logs/?${queryParams}`).then((res) => {
      if (res.status) {
        setApiLoading(false);
        setCatalogueData(
          res?.data?.results?.map((items) => {
            return { ...items, actions: items };
          })
        );
        setTotalItems(res?.data?.count);
      }
    });
  };
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCatlogueData(
        currentPage,
        selectedClient
      );
    }, 500); 
  
    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, selectedClient]);

  useEffect(() => {
    fetchKosks();
  }, []);

  const handleFilterChange = () => {
    setCurrentPage(1); // Reset to first page when filters change
  };

  const fetchKosks = () => {
    Fetch(`kiosk/`).then((res) => {
      if (res.status) {
        const { data } = res;
        setKiosks(data.results);
      }
    });
  };
  const kioskOptions = kiosks.map(client => ({ value: client.id, label: client.serial_number }));
  const columns = [
    { accessor: "session_start", Header: "Session Start", width: 130,       Cell: ({ value }) =>  formatLastModified(value)
  },
    { accessor: "session_end", Header: "Session End", width: 130, Cell: ({ value }) =>  formatLastModified(value) },
    {
      accessor: "file",
      Header: "File",
      width: 200,
      Cell: ({ value }) =>
        value ? (
          <a href={value}  download>
            <Image src={downloadIcon} w={"30px"} mr={"10px"} />
          </a>
        ) : (
          "N/A"
        ),
    },    
    {
      accessor: "kiosk",
      Header: "Kiosk",
      width: 200,
      Cell: ({ value }) =>  value?.name ? value?.name : 'NA'
       }
  ];



  return (
    <Box>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Flex p="6px 0px 22px 0px" justify={"space-between"}>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Session Logs
          </Text>
        </Flex>

        <Box mb={4} p={4} borderWidth="1px" borderRadius="md" bg="white">
          <HStack spacing={10} wrap="wrap" justifyContent="flex-end">
            <FormControl minW="200px" maxW="250px">
              <FormLabel fontSize="sm">Kiosk</FormLabel>
              <SearchableSelect
                options={kioskOptions}
                value={selectedClient}
                onChange={(value) => {
                  setSelectedClient(value);
                  handleFilterChange();
                }}
                placeholder="All Kiosks"
              />
            </FormControl>

          </HStack>
        </Box>

        <CardBody
          border={"1px solid lightgrey"}
          padding={"15px"}
          borderRadius="5px"
          marginBottom="20px"
          p={0}
        >
          {apiLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="blue.500" />
            </Flex>
          ) : (
            <DataTable
              table={{ columns: columns, rows: catalogueData, apiLoading }}
              path="/inbound/asn/create"
            />
          )}
        </CardBody>
      </Flex>

      {(totalItems > itemsPerPage  && !apiLoading) && (
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
        />
      )}
    </Box>
  );
}

export default withRouter(CatalogListing);