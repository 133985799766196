import Auth from "./auth";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const commonParams = {};
export default function Fetch(
  endPoint,
  params = {},
  option = {},
  props
) {

  const method = option?.method ?? "get";
  const inFormData = option?.inFormData ?? false;
  const isToken = option?.isToken ?? true;
  const url = option?.url;

  const baseURL = option?.baseURL ?? apiUrl;

  let parameters = {
    ...commonParams,
    ...params,
  };
  const FetchHeader = (token) => {
    const headers = {
      "Content-Type": inFormData ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${token || ""}`,
    };
    if (!token) {
      delete headers["Authorization"];
    }
    return {
      headers,
    };
  };
  const convertToForm = () => {
    let formData = new FormData();
    for (let name in parameters) {
      if (Array.isArray(parameters[name])) {
        for (var i = 0; i < parameters[name].length; i++) {
          formData.append(`${name}`, parameters[name][i]);
        }
      } else {
        formData.append(name, parameters[name]);
      }
    }
    return formData;
  };

  const fetch = async (token) => {
    return await axios[method](
      url ? url : baseURL + endPoint,
      inFormData
        ? convertToForm()
        : Object.keys(parameters)?.length
          ? parameters
          : FetchHeader(token),
      FetchHeader(token)
    )
      .then((d) => {

        const dataParse = { data: d.data, status: true };
        return dataParse;
      })
      .catch(async (err) => {
        if (err?.response?.status === 500) {
          return { err: ["Something Went Wrong"], status: false };
        } else if (err?.response?.status === 401) {
          let errRes = {}
          const newToken = await refreshToken();
          if (newToken === 'expired') {
            window.location.href = "/auth/sign";

            errRes = { err: ["expired"], status: false };
          } else if (newToken) {
            errRes = await fetch(newToken);
          } else {
            errRes = { err: ["Unauthorized"], status: false };
          }

          return errRes
        } else {
          return { data: err?.response?.data, status: false };
        }
      });
  };
  if (isToken === false) {
    return fetch(isToken);
  }
  return Auth.getAsyncToken().then((res) => fetch(res.token));
}
export async function refreshToken() {
  const refresh_token = localStorage.getItem("refresh_token");
  if (refresh_token) {
    let expTime = decodeToken(refresh_token);
    let expireToken = isTokenExpired(expTime)
    if (expireToken) {
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token')

      return 'expired';
    }
  }
  if (refresh_token) {


    const refreshResponse = await axios.post(
      `${apiUrl}accounts/refresh/`,
      { refresh: refresh_token }
    );
    if (refreshResponse?.data?.access) {
      const newToken = refreshResponse.data.access;
      localStorage.setItem("token", newToken);
      document.cookie = `token=${newToken}`;
      return newToken;
    } else {
      return null;
    }
  }
}
export const isTokenExpired = (token) => {
  const expirationTime = token.exp;

  const currentTime = Math.floor(Date.now() / 1000);
  return expirationTime < currentTime;
}
export const decodeToken = (token) => {
  const a = token.split('.')[1];
  const b = a.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(b).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};